import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { DeleteproductsComponent } from '../deleteproducts/deleteproducts.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  addCategoryForm: FormGroup;
  baseurl : string="";
  AllCategoryInfo : any;
  AllCategoryNames : any;
  loading : false;
  previewImg: any = "../../../assets/Icon ionic-ios-add-circle-1.png";
  displayAdd : boolean = false;
  pageNo: number = 1;
  totalPageCount: number = 1;
  pageNumbers: Array<any> = [1,2,3,4,5];
  searchByCategoryName : any = FormGroup;

  constructor(
    private adminSerivce : AdminService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.baseurl = this.adminSerivce.baseUrl;
    this.addCategoryForm = this.fb.group({
      image : ["", Validators.required],
      categoryName: ["", Validators.required]
    });
    this.searchByCategoryName = this.fb.group({
      searchText : ["", Validators.required]
    });
    this.getCategories();
  }


  getCategories() {
    let obj={
      type:"All"
    }
    this.adminSerivce.showLoader.next(true);
    this.adminSerivce.getAllCategories(obj).subscribe(
      (posRes:any) => {
        if (posRes.response == 3) {
          this.AllCategoryInfo = posRes.CategoryInfo;
          let pInfo =[];
          this.AllCategoryInfo.filter(function (el){
            let obj = {
              "BrandName" : el.categoryName,
              "Brandimage" : el.CategoryImage,
              "BrandID" : el.categoryID
            }
            pInfo.push(obj);
          })
          this.AllCategoryNames = pInfo;
          this.adminSerivce.showLoader.next(false);
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  getCategories1() {
    let obj={
      type:"All"
    }
    this.adminSerivce.getAllCategories(obj).subscribe(
      (posRes:any) => {
        //console.log(posRes);
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          this.AllCategoryInfo = posRes.CategoryInfo;
          let pInfo =[];
          this.AllCategoryInfo.filter(function (el){
            let obj = {
              "BrandName" : el.categoryName,
              "Brandimage" : el.CategoryImage,
              "BrandID" : el.categoryID
            }
            pInfo.push(obj);
          })
          this.AllCategoryNames = pInfo;
         // this.openSnackBar(posRes.message, "");
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  AddCategory(){
    let dialogRef = this.dialog.open(AddCategoryComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "add",data : null}
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getCategories1();
      }
    })
  }
  viewCategory(product:any){
    // this.openSnackBar("In Progress ","");
    let dialogRef = this.dialog.open(AddCategoryComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "view",data : product}
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getCategories();
      }
    })
  }
  editCategory(product:any){
   // this.openSnackBar("In Progress ","");
    let dialogRef = this.dialog.open(AddCategoryComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "edit",data : product }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getCategories1();
      }
    })
  }
  deletCategory(product:any){
    let dialogRef = this.dialog.open(DeleteproductsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "category",data : product}
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res.isDelete) {
        this.DeletCategories(res.deleteID);
        // this.pageNo = 1;
      }
    })
  }
  DeletCategories(dleteId:string){
    this.adminSerivce.showLoader.next(true);
    let obj={
      type:dleteId
    }
    let token = sessionStorage.getItem('token');
    this.adminSerivce.DeleteCategories(obj,token).subscribe(
      (posRes:any) => {
        //console.log(posRes);
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          this.getCategories();
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  searchCategoryInsert(){
    let payload = {...this.searchByCategoryName.value}
    if(payload.searchText.trim() != ""){
      this.adminSerivce.showLoader.next(true);
      let obj={
        type:"All",
        text : payload.searchText
      }
      let token = sessionStorage.getItem('token');
      this.adminSerivce.SearchCategories(obj,token).subscribe(
        (posRes:any) => {
          //console.log(posRes);
          if (posRes.response == 3) {
            this.adminSerivce.showLoader.next(false);
            this.AllCategoryInfo = posRes.categoriesList;
            let pInfo =[];
            this.AllCategoryInfo.filter(function (el){
              let obj = {
                "BrandName" : el.categoryName,
                "Brandimage" : el.CategoryImage,
                "BrandID" : el.categoryID
              }
            pInfo.push(obj);
          })
          this.AllCategoryNames = pInfo;
         // this.openSnackBar(posRes.message, "");
          } else {
            this.adminSerivce.showLoader.next(false);
            this.openSnackBar(posRes.message, "");
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.adminSerivce.showLoader.next(false);
            this.openSnackBar(err.message, "Client Error");
            console.log(err.message);
          } else {
            this.openSnackBar(err.message, "Server Error");
            console.log(err.message);
          }
        })
    }else{
      this.getCategories();
    }
    
  }

   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
