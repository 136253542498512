import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-devine-payments',
  templateUrl: './devine-payments.component.html',
  styleUrls: ['./devine-payments.component.css']
})
export class DevinePaymentsComponent implements OnInit {

  bookingsForm: FormGroup;
  bookingsForm1: FormGroup;
  searchByNameForm: FormGroup;

  isUserDetails: boolean = false;
  isOnline: boolean = false;
  onlinetype: string = "All";
  selectedstatus_online: any = "All";
  onlineID: number = 0;
  showOptions: boolean = false;
  showOptions_online: boolean = false;
  type: string = "All";
  selectedstatus: any = "All";
  baseurl : string ="";
  calfilterType: string = "thisYear";
  Isclicked: boolean = false;
  Isclicked_online: boolean = false;
  message: string = "Finding PaymentData..";
  ManualData: Array<any> = [];
  paymentDetails: Array<any> = [];
  status: any = {
    getTypeFilter: "thisYear"
  };

  public lineChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public lineChartData: ChartDataSets[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            color: "rgba(0, 0, 0,0)"
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 1
          },
          gridLines: {
            color: "rgba(0, 0, 0,0)"
          }
        }
      ]
    },
    elements:
    {
      point:
      {
        radius: 8,
        hitRadius: 40,
        hoverRadius: 10,
        hoverBorderWidth: 5
      }
    },
    legend: {
      position: 'top',
      labels: {
        fontSize: 10,
        usePointStyle: true
      }
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'skyblue',
      backgroundColor: 'rgba(255,255,255,0.3)',
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  // Bar Charts Code
  chartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      labels: {
        fontSize: 10,
        usePointStyle: true
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        }
      }],
      yAxes: [{
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        }
      }]
    }
  };

  @ViewChild('t', { static: false }) datePicker: NgbInputDatepicker;
  @ViewChild("scroll", { static: false }) scrollContainer: ElementRef;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: CdkVirtualScrollViewport;
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.bookingsForm = this.fb.group({
      dateRange: ["", Validators.required]
    })
    this.bookingsForm1 = this.fb.group({
      dateRange1: ["", Validators.required]
    })
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
    })
    this.baseurl = "https://api.nutrifyfoods.com"
    this.ProjectPaymts(this.type);
    //this.totalPaymentInfo(this.calfilterType);
  }

  ProjectPaymts(name) {
    this.showOptions = false;
    this.Isclicked = false;
    this.type = name;
    let p1Payload = {
      type: name
    }
    this.type = name;
    // console.log(this.type);    
    this.message = "No PaymentData Found.."
    this.ManualData = [];
    this.searchByNameForm.patchValue({
      searchText: ""
    })
    this.adminService.showLoader.next(true);
    this.adminService.getPayments(p1Payload).subscribe((res) => {
      if (res.response == 3) {
        this.paymentDetails = res.StatusPaymentInfo;
        this.adminService.showLoader.next(false);
      }
      else {
        this.paymentDetails = [];
        this.paymentDetails = res.StatusPaymentInfo;
        this.message = "No PaymentData Found..";
        this.adminService.showLoader.next(false);
        // this.openSnackBar(res.message, "");
      }
    })
  }

  openOptions() {
    this.showOptions = !this.showOptions;
  }
  
  //  Payment method wise  fetching
  changeView(event) {
    // console.log("a", event.value);
    if (event.value == "online") {
      this.isOnline = true;
      this.onlinetype = "All";
      this.selectedstatus_online = "All";
      this.onlineID = 6;
      // this.ProjectPaymts_online(this.onlinetype);
      // console.log("onlinestatus", this.onlinetype);
    } else {
      this.isOnline = false;
      this.showOptions = false;
      this.type = "All";
      this.selectedstatus = "All";
      // this.ProjectPaymts(this.type);
      //  this.totalPaymentInfo(this.calfilterType);
    }
  }

}
