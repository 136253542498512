import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddsubcategoryComponent } from '../addsubcategory/addsubcategory.component';
import { DeleteproductsComponent } from '../deleteproducts/deleteproducts.component';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit {
  pageNo: number = 1;
  totalPageCount: number = 1;
  pageNumbers: Array<any> = [1, 2, 3, 4, 5];
  searchBysubCategoryName: any = FormGroup;
  baseurl: string = "";
  AllCategoryInfo: any;
  dropdownCategories: any;
  AllCategoryNames: any;
  selectedSubCat: string = "All";
  constructor(
    private adminSerivce: AdminService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.baseurl = this.adminSerivce.baseUrl;
    this.searchBysubCategoryName = this.fb.group({
      searchText: ["", Validators.required]
    });
    this.getCategories();
  }

  getCategories() {
    this.adminSerivce.showLoader.next(true);
    let obj = {
      type: this.selectedSubCat
    }
    console.log(obj)
    this.adminSerivce.getAllCategories(obj).subscribe(
      (posRes: any) => {
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          this.AllCategoryInfo = posRes.CategoryInfo;
          this.dropdownCategories = posRes.CategoryInfo;
          let pInfo = [];
          this.AllCategoryInfo.filter(function (el) {
            let obj = {
              "BrandName": el.categoryName,
              "Brandimage": el.CategoryImage
            }
            pInfo.push(obj);
          })
          this.AllCategoryNames = pInfo;
          // this.openSnackBar(posRes.message, "");
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  onSelectedSubCategory() {
    let catId = "";
    if (this.selectedSubCat == 'All') {
      this.getCategories();
    } else {
      this.dropdownCategories.filter((item) => {
        if (item.categoryName == this.selectedSubCat) {
          catId = item.categoryID
        }
      })
      this.getSingleCategories(catId);
    }
  }
  getSingleCategories(catid) {
    this.adminSerivce.showLoader.next(true);
    let obj = {
      type: catid
    }
    this.adminSerivce.getAllCategories(obj).subscribe(
      (posRes: any) => {
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          this.AllCategoryInfo = posRes.CategoryInfo;
          // let pInfo =[];
          // this.AllCategoryInfo.filter(function (el){
          //   let obj = {
          //     "BrandName" : el.categoryName,
          //     "Brandimage" : el.CategoryImage,
          //     "BrandID" : el.categoryID
          //   }
          //   pInfo.push(obj);
          // })
          // this.AllCategoryNames = pInfo;
          // this.openSnackBar(posRes.message, "");
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  AddsubCategory() {
    this.searchBysubCategoryName.patchValue({
      searchText : ""
    })
    let dialogRef = this.dialog.open(AddsubcategoryComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: { option: "Add", data: {}, catName: this.selectedSubCat}
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let catId = "";
    if (this.selectedSubCat == 'All') {
      this.getCategories();
    } else {
      this.dropdownCategories.filter((item) => {
        if (item.categoryName == this.selectedSubCat) {
          catId = item.categoryID
        }
      })
      this.getSingleCategories(catId);
    }
        // this.getCategories();
      }
    })
  }
  viewsubCategory(product: any, catName: string, catid: string) {
    this.searchBysubCategoryName.patchValue({
      searchText : ""
    })
    let dialogRef = this.dialog.open(AddsubcategoryComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: { option: "view", data: product, catName: catName, catid: catid }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getCategories();
        this.pageNo = 1;
      } 
    })
  }
  editsubCategory(product: any, catName: string, catid: string) {
    this.searchBysubCategoryName.patchValue({
      searchText : ""
    })
    let dialogRef = this.dialog.open(AddsubcategoryComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: { option: "edit", data: product, catName: catName, catid: catid }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let catId = "";
        if (this.selectedSubCat == 'All') {
          this.getCategories();
        } else {
          this.dropdownCategories.filter((item) => {
            if (item.categoryName == this.selectedSubCat) {
              catId = item.categoryID
            }
          })
          this.getSingleCategories(catId);
        }
      } else {
        let catId = "";
        if (this.selectedSubCat == 'All') {
          this.getCategories();
        } else {
          this.dropdownCategories.filter((item) => {
            if (item.categoryName == this.selectedSubCat) {
              catId = item.categoryID
            }
          })
          this.getSingleCategories(catId);
        }
      }
    })
  }

  deletsubCategory(product: any) {
    this.searchBysubCategoryName.patchValue({
      searchText : ""
    })
    let dialogRef = this.dialog.open(DeleteproductsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "subcategory",data : product}
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res.isDelete) {
        this.DeletCategories(res.deleteID);
        // this.pageNo = 1;
      }
    })
  }

  DeletCategories(dleteId:string){
    this.adminSerivce.showLoader.next(true);
    let obj={
      subCategoryID:dleteId
    }
    let token = sessionStorage.getItem('token');
    this.adminSerivce.DeletesubCategories(obj,token).subscribe(
      (posRes:any) => {
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          let catId = "";
          if (this.selectedSubCat == 'All') {
            this.getCategories();
          } else {
            this.dropdownCategories.filter((item) => {
              if (item.categoryName == this.selectedSubCat) {
                catId = item.categoryID
              }
            })
            this.getSingleCategories(catId);
          }
          this.openSnackBar(posRes.message, "");
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  searchSubCategory() {
    this.AllCategoryInfo = [];
    let payload = { ...this.searchBysubCategoryName.value }
    if (payload.searchText.trim() != "") {
      let catId = "";
      if(this.selectedSubCat == 'All'){
        catId = "All";
      }else{
        this.dropdownCategories.filter((item) => {
          if (item.categoryName == this.selectedSubCat) {
            catId = item.categoryID
          }
        })
      }
      
      let obj = {
        type: catId,
        text: payload.searchText
      }
      this.adminSerivce.showLoader.next(true);
      let token = sessionStorage.getItem('token');
      this.adminSerivce.getSearchSubCategories(obj, token).subscribe(
        (posRes: any) => {
          if (posRes.response == 3) {
            this.adminSerivce.showLoader.next(false);
            this.AllCategoryInfo = posRes.categoriesList;
            // this.dropdownCategories = posRes.CategoryInfo;
            // let pInfo =[];
            // this.AllCategoryInfo.filter(function (el){
            //   let obj = {
            //     "BrandName" : el.categoryName,
            //     "Brandimage" : el.CategoryImage
            //   }
            //   pInfo.push(obj);
            // })
            // this.AllCategoryNames = pInfo;
            // this.openSnackBar(posRes.message, "");
          } else {
            this.adminSerivce.showLoader.next(false);
            this.openSnackBar(posRes.message, "");
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.adminSerivce.showLoader.next(false);
            this.openSnackBar(err.message, "Client Error");
            console.log(err.message);
          } else {
            this.openSnackBar(err.message, "Server Error");
            console.log(err.message);
          }
        })

    } else {
      let catId = "";
      if (this.selectedSubCat == 'All') {
        this.getCategories();
      } else {
        this.dropdownCategories.filter((item) => {
          if (item.categoryName == this.selectedSubCat) {
            catId = item.categoryID
          }
        })
        this.getSingleCategories(catId);
      }
    }
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
