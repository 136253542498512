import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TaskDetailsComponent } from './components/task-details/task-details.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AllTasksListComponent } from './components/all-tasks-list/all-tasks-list.component';
import { AlCustomersComponent } from './components/al-customers/al-customers.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { AllBookingsComponent } from './components/all-bookings/all-bookings.component';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReferralsComponent } from './components/referrals/referrals.component';
import { AdvatageComponent } from './components/advantages/advatages.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { PurchaseProjectsComponent } from './components/purchase-projects/purchase-projects.component';
import { AddProjectComponent } from './components/add-project/add-project.component';
import { PurchaseBookingsComponent } from './components/purchase-bookings/purchase-bookings.component';
import { WithdrawBookingsComponent } from './components/withdraw-bookings/withdraw-bookings.component';
import { DevinePaymentsComponent } from './components/devine-payments/devine-payments.component';
import { OrdersComponent } from './components/orders/orders.component';
import { DivinereportsComponent } from './components/divinereports/divinereports.component';
import { DivinedashboardComponent } from './components/divinedashboard/divinedashboard.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { SubcategoryComponent } from './components/subcategory/subcategory.component';
import { ProductsComponent } from './components/products/products.component';
const routes: Routes = [
  {path:"login",component:LoginComponent},
  {path:"admin",component:AdminComponent,children:[
    {path:"dashboard",component:DivinedashboardComponent},
    {path:"settings",component:SettingsComponent},
    {path:"task_list",component:AllTasksListComponent},
    {path:"purchaseProjects",component:PurchaseProjectsComponent},
    {path:"purchaseBookings",component:PurchaseBookingsComponent},
    {path:"addprojects",component:AddProjectComponent},
    {path:"withdrawBookings",component:WithdrawBookingsComponent},
    {path:"MostPopularprojects",component:AdvatageComponent},
    {path:"customers",component:AlCustomersComponent},
    {path:"referrals",component:ReferralsComponent},
    {path:"allBookings",component:AllBookingsComponent},
    {path:"reports",component:ReportsComponent},
    {path:"AddCategories",component:CategoriesComponent},
    {path:"subCategories",component:SubcategoryComponent},
    {path:"products",component:ProductsComponent},
    {path:"notifications",component:NotificationsComponent},
    {path:"booking/:id",component:BookingDetailsComponent},
    {path:"details/:id",component:TaskDetailsComponent},
    {path:"customer/:id",component:CustomerDetailsComponent},
    {path:"payments", component:PaymentsComponent},
    {path:"payments1",component:DevinePaymentsComponent},
    {path:"orderInfo",component:OrdersComponent},
    {path:"divinereport",component:DivinereportsComponent},
    {path:"**",redirectTo:"dashboard",pathMatch:"full" }
  ]},
  {path:"**",redirectTo:"login",pathMatch:"full"}
 
 
];
@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
