import { AbstractControl, FormControl, FormGroup, ValidatorFn } from "@angular/forms";

const isRequired = (value: string) => value === '' ? false : true;
export function firstNameValidator (control: FormControl) {
    let valid = false;
    var fname = control.value.trim();
    valid = isRequired(fname);
    return valid ? null : { invalidfname : true, message:"FirstName is required." };
};
export function vmartPriceValidator(mrpControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mrpPrice = mrpControl.value;
      const vmartPrice = control.value;
  
      if (vmartPrice && mrpPrice && parseInt(vmartPrice) >= parseInt(mrpPrice)) {
        return { vmartPriceExceeded: true };
      }
  
      return null;
    };
  }
  export function priceComparisonValidator(formGroup: FormGroup) {
    const MRP_Price = formGroup.get('MRP_Price').value;
    const VMART_Price = formGroup.get('VMART_Price').value;
  
    if (MRP_Price !== '' && VMART_Price !== '' && parseInt(VMART_Price) >= parseInt(MRP_Price)) {
      return { priceComparison: true };
    }
  
    return null;
  }
export function imageValidator (control: FormControl) {
    let valid = false;
    const fileInput = control.value;
    console.log(fileInput);

    // Check if a file is selected
    if (!fileInput) {
        return { invalidFile: true, message: "Please select an image." };
    }

    // Check file type
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    console.log(fileInput.name);
    if (!allowedExtensions.test(fileInput.name)) {
        return { invalidFileType: true, message: "Invalid file type. Only JPG, JPEG, PNG, and GIF files are allowed." };
    }
   
    // Check file size (in bytes)
    // const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    // if (fileInput.size > maxSizeInBytes)  {
    //     return { invalidFileSize: true, message: "File size exceeds the limit. Maximum size allowed is 5MB." };
    // }
    return null;
};

export function catDropDownValidator(control: FormControl) {
    let valid = false;
    const DDvalue = control.value.trim();
    
    if(DDvalue === "All"){
        return valid ? null : { invalidAll: true, message:"select a categoroy not ALL" };
    }
    return null;
};

export function emailValidator(control: FormControl) {
    let valid = false;
    const email = control.value.trim();
    valid = isRequired(email);
    if(!valid){
        return valid ? null : { invalidEmail: true, message:"Email is not Empty" };
    }
    valid =isEmailValid(email);
    if(!valid){
        return valid ? null : { invalidEmail: true, message:"Email is not valid" };
    }
    return null;
};
export function mobileNumberValidator (control: FormControl) {
    let valid = false;
    var mobileNumber = control.value.trim();
    valid =isMobileValid(mobileNumber);
    return valid ? null : { invalidMobile : true, message:"Phone  number is not valid" };
};
export function postalCodeValidator (control: FormControl) {
    let valid = false;
    var postalcode = control.value.trim();
    valid =isPostalCodeValid(postalcode);
    return valid ? null : { invalidpostCode : true, message:"invalid Postal Code" };
};
const isPostalCodeValid = (postcode : string) =>{
    var expr = /^[1-9][0-9]{5}$/gi;
    return expr.test(postcode);
}
const isMobileValid = (mobile: string) => {
    var expr = /^[6-9]\d{9}$/gi;
    return expr.test(mobile);
}
const isEmailValid = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
