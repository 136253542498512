import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CategoriesComponent } from '../categories/categories.component';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catDropDownValidator } from '../../validators/validations';
@Component({
  selector: 'app-addsubcategory',
  templateUrl: './addsubcategory.component.html',
  styleUrls: ['./addsubcategory.component.css']
})
export class AddsubcategoryComponent implements OnInit {
  previewUrl: any = "../../../assets/ionic-add-circle.png";
  addCategoryForm: FormGroup;
  AllCategoryInfo: any;
  loading: boolean = false;
  isLoading : boolean = false;
  isReadOnly :boolean = false;
  isUpdateOnly :boolean = false;
  baseUrl : string = "";
  imgFile: File;
  options : string ="new";
  categoryID: string ="";
  categoryName : string = "";
  constructor(
    private dialogRef: MatDialogRef<CategoriesComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private adminSerivce: AdminService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.baseUrl = this.adminSerivce.baseUrl;
    this.addCategoryForm = this.fb.group({
      images: ["", [Validators.required,this.imageValidators]],
      categoryName: ["All", [Validators.required,catDropDownValidator]],
      subCategoryName: ["", Validators.required]
    })
    this.categoryName = this.data.catName
    this.getAllCategories();
  }
  // Custom validator for image control
  imageValidators(control: AbstractControl): { [key: string]: any } | null {
    const imageValue = control.value;
    if (!imageValue || imageValue === "") {
      return { 'required': true };
    }
    // You can add additional image validation logic here if needed.
    return null;
  }
  dataEditpatchValues(){
    let products= this.data.data;
    this.addCategoryForm.patchValue({
      categoryName: this.categoryName,
      subCategoryName : products.subCategoryName
    })
    this.previewUrl = this.baseUrl + products.SubCategoryProfilePic; 
    this.http
      .get(""+this.previewUrl, { responseType: "blob" })
      .subscribe((file) => {
        let imgFile = new File([file], "userImg.jpg");
        this.addCategoryForm.get("images").setValue(imgFile);
      });
      let pobj = {...this.addCategoryForm.value}
  }
  datapatchValues(){
    let products= this.data.data;
    this.addCategoryForm.patchValue({
      categoryName: this.categoryName,
      subCategoryName : products.subCategoryName
    })
    if (products.SubCategoryProfilePic !== "") {
      this.previewUrl = this.baseUrl + products.SubCategoryProfilePic;
    }
    let pobj = {...this.addCategoryForm.value}
  }
  getAllCategories() {
    let obj = {
      type: "All"
    }
    this.adminSerivce.showLoader.next(true);
    this.adminSerivce.getAllCategories(obj).subscribe(
      (posRes: any) => {
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          this.AllCategoryInfo = posRes.CategoryInfo;
          this.categoryID = this.data.catid;
          this.categoryName = this.data.catName;
          this.AllCategoryInfo.filter((item) => {
            if (item.categoryName == this.categoryName) {
             this.categoryID = item.categoryID 
              this.addCategoryForm.patchValue({
                categoryName:this.categoryName
              })
            }
          })
          if(this.data.option == "view"){
            this.options = "view";
            this.isReadOnly = true;
            this.datapatchValues();
          }
          if(this.data.option == "edit"){
            this.options = "edit";
            this.isUpdateOnly = true;
            this.dataEditpatchValues();
          }
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  //File Upload

  fileProgress(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.addCategoryForm.get("images").setValue(file);
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  closeTab() {
    this.dialogRef.close(false);
  }
  subCategoryInsert() {
    if(this.addCategoryForm.valid){
      if(this.options == "edit"){
        this.loading = true;
        this.isLoading = true;
        if (this.addCategoryForm.value.images != "") {
          let payLoad = { ...this.addCategoryForm.value };
          delete payLoad.images;
          if (payLoad.categoryName != "All") {
            let formData = new FormData();
            let catId = "";
            this.AllCategoryInfo.filter((item) => {
              if (item.categoryName == payLoad.categoryName) {
                catId = item.categoryID
              }
            })
            let apiPayload = {
              categoryID: catId,
              subCategoryName: payLoad.subCategoryName,
              subCategoryID : this.data.data.subCategoryID
            }
            formData.append("subimage", this.addCategoryForm.get("images").value);
            formData.append("subCategoryupdateInfo", JSON.stringify(apiPayload));
            let token = sessionStorage.getItem('token');
            this.adminSerivce.showLoader.next(true);
            this.adminSerivce.updatesubCategory(formData,token).subscribe(
              (posRes:any) => {
                if (posRes.response == 3) {
                  this.adminSerivce.showLoader.next(false);
                  this.loading = false;
                  this.isLoading = false;
                  this.openSnackBar(posRes.message, "");
                  this.dialogRef.close(true);
                } else {
                  this.loading = false;
                  this.isLoading = false;
                  this.adminSerivce.showLoader.next(false);
                  this.openSnackBar(posRes.message, "");
                }
              },
              (err: HttpErrorResponse) => {
                if (err.error instanceof Error) {
                  this.adminSerivce.showLoader.next(false);
                  this.loading = false;
                  this.isLoading = false;
                  this.openSnackBar(err.message, "Client Error");
                } else {
                  this.loading = false;
                  this.isLoading = false;
                  this.openSnackBar(err.message, "Server Error");
                }
              })
    
          } else {
            this.openSnackBar("select a category", "")
          }
        } else {
          this.openSnackBar("Please upload category image", "");
        }
      }else{
        this.loading = true;
        this.isLoading = true;
        if (this.addCategoryForm.value.images != "") {
          let payLoad = { ...this.addCategoryForm.value };
          delete payLoad.images;
          if (payLoad.categoryName != "All") {
            let formData = new FormData();
            let catId = "";
            this.AllCategoryInfo.filter((item) => {
              if (item.categoryName == payLoad.categoryName) {
                catId = item.categoryID
              }
            })
            let apiPayload = {
              categoryID: catId,
              subCategoryName: payLoad.subCategoryName
            }
            formData.append("subimage", this.addCategoryForm.get("images").value);
            formData.append("subCategoryInfo", JSON.stringify(apiPayload));
            let token = sessionStorage.getItem('token');
            this.adminSerivce.showLoader.next(true);
            this.adminSerivce.insertsubCategory(formData,token).subscribe(
              (posRes:any) => {
                if (posRes.response == 3) {
                  this.loading = false;
                  this.isLoading = false;
                  this.adminSerivce.showLoader.next(false);
                  this.openSnackBar(posRes.message, "");
                  this.dialogRef.close(true);
                } else {
                  this.loading = false;
                  this.isLoading = false;
                  this.adminSerivce.showLoader.next(false);
                  this.openSnackBar(posRes.message, "");
                  this.dialogRef.close(false);
                }
              },
              (err: HttpErrorResponse) => {
                if (err.error instanceof Error) {
                  this.adminSerivce.showLoader.next(false);
                  this.loading = false;
                  this.isLoading = false;
                  this.openSnackBar(err.message, "Client Error");
                } else {
                  this.loading = false;
                  this.isLoading = false;
                  this.openSnackBar(err.message, "Server Error");
                }
              })
    
          } else {
            this.openSnackBar("select a category", "")
          }
        } else {
          this.openSnackBar("Please upload category image", "");
        }
      }
    }else{
      this.openSnackBar("Enter Required fields","");
    }
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }

}
