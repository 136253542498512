import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-divinedashboard',
  templateUrl: './divinedashboard.component.html',
  styleUrls: ['./divinedashboard.component.css']
})
export class DivinedashboardComponent implements OnInit {
  Totalusers: any;
  TotalOrders: any;
  thisMonthTotalusers: any;
  thisMonthTotalOrders: any;
  bookingsForm: FormGroup;
  status: any = {
    getTypeFilter: "thisYear"
  };
  isBookingDateRange: boolean = false;
  postOverViewForm: FormGroup;
  locationWiseForm: FormGroup;
  bookingData: any;
  isLocationBase: boolean = false;
  maxDate: any = new Date();
  isPostJobDateRange: boolean = false;
  totalAmount : string ="";
  //Line Chart Codes
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            color: "rgba(0, 0, 0,0)"
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 1
          },
          gridLines: {
            color: "rgba(0, 0, 0,0)"
          }
        }
      ]
    },
    elements:
    {
      point:
      {
        radius: 8,
        hitRadius: 40,
        hoverRadius: 10,
        hoverBorderWidth: 5
      }
    },
    legend: {
      position: 'top',
      labels: {
        fontSize: 10,
        usePointStyle: true
      }
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'skyblue',
      backgroundColor: 'rgba(255,255,255,0.3)',
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  // Bar Charts Code
  chartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      labels: {
        fontSize: 10,
        usePointStyle: true
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        }
      }],
      yAxes: [{
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        }
      }]
    }
  };
  public barChartLabels: Label[] = ['AC', 'Admin', 'Baby Sitting', 'Cleaning', 'Design', 'Electrition', 'Assembling',
    'Handyman', 'Mini Task', 'More', 'Moving', 'Painter', 'Photographer', 'Plumber', 'Promoter', 'Waiter/Waitress'];
  public barChartLabelsLocation: Label[]
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [];
  public barChartDataLocation: ChartDataSets[] = [];

  public barChartColors: Color[] = [
    {
      // backgroundColor: [
      //   'aqua', 
      //   '#00afaa', '#6abf4a', '#93d501', '#cede01', '#843274', '#ffd101', '#c9a978', '#ff671c',
      //   '#fa0e0e', '#843274', 'green', 'yellow', '#cede01', 'orange', '#dc3545', '#3f51b5', '#00afaa', '#6abf4a', '#93d501', '#cede01', '#ffd101','#1589FF']
    backgroundColor : ["#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411","#5856d6"]
    }
  ]
  constructor(
    private adminService : AdminService,
    private snackBar: MatSnackBar,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.bookingsForm = this.fb.group({
      dateRange: ["", Validators.required]
    })
    this.getDasboardData();
    this.getBookingStatus();
  }

  getDasboardData(){
    this.adminService.showLoader.next(true);
    let token = sessionStorage.getItem('token');
    this.adminService.devineDashbord(token).subscribe((posRes) => {
      if (posRes.response == 3) {
        this.Totalusers = posRes.message.Totalusers;
        this.TotalOrders = posRes.message.TotalOrders;
        this.thisMonthTotalusers = posRes.message.CurrentMonthTotalusers;
        this.thisMonthTotalOrders = posRes.message.CurrentTotalOrders;
        this.totalAmount = posRes.message.TotalAmount;
        this.adminService.showLoader.next(false); 
      } else {
        this.openSnackBar(posRes.message, "");
        this.adminService.showLoader.next(false);
      }
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);
      this.openSnackBar(err.message, "");
      if (err.error instanceof Error) {
        console.warn("CSE", err.message);
      } else {
        console.warn("SSE", err.message);

      }
    })
  }
  thisMonthUser(thisMonth){
    this.router.navigate(['/admin', 'customers'], { queryParams: { search_term: thisMonth } });
  }
  thisOrderUser(thisMonth){
    this.router.navigate(['/admin', 'orderInfo'], { queryParams: { search_term: thisMonth } });
  }
  getThisWeek() {
    this.isBookingDateRange = false;
    this.status = {
      getTypeFilter: "thisWeek"
    }
    let label = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat','Sun'];
    this.lineChartLabels = label;
    this.getBookingStatus()
  }
  getThisMonth(){
    this.isBookingDateRange = false;
    this.status = {
      getTypeFilter: "thisMonth"
    }
    let label = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
      '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',
      '25', '26', '27', '28', '29', '30', '31'];
    this.lineChartLabels = label;
    this.getBookingStatus()
  }
  getThisYear(){
    this.isBookingDateRange = false;
    this.status = {
      getTypeFilter: "thisYear"
    }
    let label = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    this.lineChartLabels = label;
    this.getBookingStatus()
  }
  getBookingByRange(){
    this.isBookingDateRange = true;
    // this.status = {
    //   getTypeFilter : "[1598400000,1600905599]"
    // }
    let frmDate = new Date(this.bookingsForm.value.dateRange[0]).getTime();
    let toDate = new Date(this.bookingsForm.value.dateRange[1]).getTime();
    frmDate = new Date(this.bookingsForm.value.dateRange[0]).setHours(0, 0, 0, 0);
    toDate = new Date(this.bookingsForm.value.dateRange[1]).setHours(23, 59, 59, 999);
    this.status = {
      getTypeFilter: JSON.stringify([frmDate, toDate])
    }
    console.log(this.status);

    this.getBookingStatus()
  }
  getBookingStatus() {
    this.adminService.showLoader.next(true);
    this.lineChartData = []
    let token = sessionStorage.getItem('token');
    this.adminService.fetchOrdersStatus(this.status, token).subscribe((posRes) => {
      this.adminService.showLoader.next(false);
      // console.log("Booking", posRes);
      if (posRes.response == 3) {
        if (!this.isBookingDateRange) {
          this.bookingData = posRes.result;
          this.lineChartData = [this.bookingData];
        } else {
          this.bookingData = { label: "Date Range", data: Object.values(posRes.result.data[0]) };

          this.lineChartLabels = Object.keys(posRes.result.data[0])
          this.lineChartData = [this.bookingData];

        }
      } else {
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }

   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}
