import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/admin.service';
import { FiltersComponent } from '../filters/filters.component';
import { FiltersdevineComponent } from '../filtersdevine/filtersdevine.component';

@Component({
  selector: 'app-al-customers',
  templateUrl: './al-customers.component.html',
  styleUrls: ['./al-customers.component.css'],
})
export class AlCustomersComponent implements OnInit, OnDestroy {
  filtercustomer: string = "All";
  UserTypes: string = "ProfileUpdate";
  filterOption: string = "All";
  baseUrl: string = "";
  jobs: any = [];
  taskStatus: string = "";
  customerSubscribe: any;
  customers: Array<any> = [];
  maxDate: any = new Date()
  filterdCustomers: Array<any> = [];
  pageNo: number = 1;
  message: string = "Finding Users.."
  totalPageCount: number = 1;
  isTotalCountReached: boolean = false;
  debounceTime = null;
  isFetchingUsers: boolean = false;
  filterForm: FormGroup;
  searchByNameForm: FormGroup;
  searchByNameForm1: FormGroup;
  customerObj: any = null;
  isSearchByName: boolean = false;
  isSearchByNameProfileNot : boolean = false;
  isDetails: boolean = false
  keywordSearch: any;
  customerID: string = ""
  currentPage: number = 0;
  isProfileNotUpdate: boolean = false;
  allcustomer : boolean = true;
  pageNumbers: Array<any> = [];
  categoryList: Array<any> = [];
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: CdkVirtualScrollViewport;
  image: string = "https://api.nutrifyfoods.com/images/Customers/NYlLT1600410727105JPEG_20200918_143028_1044443140.jpg"
  
  //divine params
  divineCustomerObj: any = null;
  divinePageNo :number = 1;
  divineSize : string = "21"; 
  divineCustomers: Array<any> = [];
  divineDateFilter : boolean = false;
  constructor(private adminService: AdminService, private router: Router, private fb: FormBuilder,
    private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private dialog: MatDialog) { }

  ngOnInit() {
    let cat = JSON.parse(sessionStorage.getItem('categories'));
    this.isProfileNotUpdate = false;
    // if (cat && cat.length) {
    //   this.categoryList = cat;
    // } else {
    //   this.browseCategory();
    // }

    this.activatedRoute.queryParamMap.subscribe(params => {
      this.keywordSearch = { ...params };
    });
    if (this.keywordSearch && this.keywordSearch.params && this.keywordSearch.params.search_term) {
      this.filterOption = this.keywordSearch.params.search_term
    }
    
    this.baseUrl = "https://api.nutrifyfoods.com"
    this.filterForm = this.fb.group({
      dateRange: [null]
    })

    this.searchByNameForm = this.fb.group({
      userID: ["", Validators.required],
      type : ["Users"],
      pageNo: [""],
      size: ["21"]
    })
    this.searchByNameForm1 = this.fb.group({
      userID: ["", Validators.required],
      type : ["NoUsers"],
      pageNo: [""],
      size: ["21"]
    })
    this.adminService.showLoader.next(true);
    if(this.filterOption=="thisMonth"){
      var date = new Date(), y = date.getFullYear(), m = date.getMonth();
      var firstDay = new Date(y, m, 1).setHours(0, 0, 0, 0);
      var lastDay = new Date(y, m + 1, 0).setHours(23, 59, 59, 999);
      this.divineCustomerObj = {
        pageNo:""+this.divinePageNo,
        size:this.divineSize,
        fromdate:""+firstDay,
        todate:""+lastDay
      }
      this.getDateDivineFiltersData();
    }else{
      this.fetchAllDivineUsers();
    }
    // if(this.filterOption=="NotUpdated"){
    //   this.customerObj = {
    //     type: "All",
    //     "sortBy": "All",
    //     pageNo: "" + this.pageNo,
    //     size: "15",
    //     State: "All",
    //     category: "All"
    //   }
    //   this.isProfileNotUpdate = true;
    //   this.ProfileNotUpdateUser();
    // }else{
    //   var strJson = {
    //     filterType: this.filterOption
    //   }
    //   var strget = JSON.stringify(strJson);
    //   var typeFilter = strJson.filterType;
    //   if (typeFilter[0] == "[") {
    //     var a = strJson.filterType;
    //     var str = a.substr(1, a.length - 2);
    //     var ar = str.split(",");
    //     this.customerObj = {
    //       type: this.filtercustomer,
    //       "sortBy": "All",
    //       pageNo: "" + this.pageNo,
    //       size: "15",
    //       State: "All",
    //       category: "All",
    //       fromdate: ar[0],
    //       todate: ar[1]
    //     }
    //   } else {
    //     this.customerObj = {
    //       type: this.filtercustomer,
    //       "sortBy": this.filterOption,
    //       pageNo: "" + this.pageNo,
    //       size: "15",
    //       State: "All",
    //       category: "All"
    //     }
    //   }
  
    //   //// console.log("che",customerObj);
    //   this.fetchAllUsers()
    //   // this.baseUrl = this.adminService.baseUrl;
    // }

    
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
  getDateDivineFiltersData(){
    this.divineDateFilter = true;
    let token = sessionStorage.getItem('token');
    this.customerSubscribe = this.adminService.fetchAllCustomers(this.divineCustomerObj, token).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.message = "No Users Found.."
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.divineCustomers = posRes.FetchData;
        if(this.divineCustomers.length>0){
          this.totalPageCount = posRes.pages;
          this.pageNumbers =[];
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          if (this.totalPageCount <= this.divinePageNo) {
            this.isTotalCountReached = true;
          } else {
            this.isTotalCountReached = false;
          }
         // let parentElm = document.getElementById('main-wrap');
          //let  currentScrollPositio = parentElm.pageYOffset;
         //  let currentScrollPosition = parentElm.scrollTop;
          //// console.log("Yposition",currentScrollPosition);
          // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
          this.filterdCustomers = this.divineCustomers;
          this.isFetchingUsers = false;
          //  parentElm.scrollTop = currentScrollPosition;
          // parentElm.scrollTop = 0;
        }else{
          this.openSnackBar(posRes.message, "");
          this.pageNo = this.pageNo - 1;
          this.totalPageCount = 1;
          this.isFetchingUsers = false;
        }
        
      } else {
        this.openSnackBar(posRes.message, "");
        this.pageNo = this.pageNo - 1;
        this.totalPageCount = 1;
        this.isFetchingUsers = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingUsers = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }
  fetchAllDivineUsers(){
    this.divineCustomerObj = {
        pageNo:""+this.divinePageNo,
        size:this.divineSize
    }
    let token = sessionStorage.getItem('token');
    this.customerSubscribe = this.adminService.fetchAllCustomers(this.divineCustomerObj, token).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.message = "No Users Found.."
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.divineCustomers = posRes.FetchData;
        if(this.divineCustomers.length>0){
          this.totalPageCount = posRes.pages;
          this.pageNumbers =[];
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          if (this.totalPageCount <= this.divinePageNo) {
            this.isTotalCountReached = true;
          } else {
            this.isTotalCountReached = false;
          }
         // let parentElm = document.getElementById('main-wrap');
          //let  currentScrollPositio = parentElm.pageYOffset;
         //  let currentScrollPosition = parentElm.scrollTop;
          //// console.log("Yposition",currentScrollPosition);
          // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
          this.filterdCustomers = this.divineCustomers;
          this.isFetchingUsers = false;
          //  parentElm.scrollTop = currentScrollPosition;
          // parentElm.scrollTop = 0;
        }else{
          this.openSnackBar(posRes.message, "");
          this.totalPageCount = 1;
          this.pageNo = this.pageNo - 1;
          this.isFetchingUsers = false;
        }
       
      } else {
        this.openSnackBar(posRes.message, "");
        this.totalPageCount = 1;
        this.pageNo = this.pageNo - 1;
        this.isFetchingUsers = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingUsers = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  // Browse Category
  browseCategory() {
    this.adminService.browseCategory().subscribe((posRes) => {
      if (posRes.response == 3) {
        this.categoryList = posRes.categoriesList;
        sessionStorage.setItem('categories', JSON.stringify(this.categoryList));
      } else {
        this.openSnackBar(posRes.message, "")
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.warn("CSE", err.message);
      } else {
        console.warn("SSE", err.message);

      }
    })
  }
  ProfileNotUpdateUser() {
    this.pageNumbers = [];
    this.message = "Finding Users.."
    this.isSearchByName = false;
    this.isFetchingUsers = true;
    this.customerObj.pageNo = "1";
    this.customers = [];
    this.adminService.showLoader.next(true);
    let token = sessionStorage.getItem('token');
    let obj = {
      "pageNo": "1",
      "size": "20",
      "type": "All",
      "State": "All",
      "category": "All",
      "sortBy": "All"
    }
    this.pageNo =1;
    this.filterdCustomers = [];
    this.customerSubscribe = this.adminService.fetchNotProfileUpdateCustomers(obj, token).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.message = "No Users Found.."
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.customers = posRes.FetchData;
        this.totalPageCount = posRes.pages;
        for (let i: number = 0; i < this.totalPageCount; i++) {
          this.pageNumbers.push(i + 1);
        }
        if (this.totalPageCount <= this.pageNo) {
          this.isTotalCountReached = true;
        } else {
          this.isTotalCountReached = false;
        }
        // let parentElm = document.getElementById('main-wrap1');
        //let  currentScrollPositio = parentElm.pageYOffset;
       // let currentScrollPosition = parentElm.scrollTop;
        //// console.log("Yposition",currentScrollPosition);
        // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
        this.filterdCustomers = this.customers
        this.isFetchingUsers = false;
        //  parentElm.scrollTop = currentScrollPosition;
       // parentElm.scrollTop = 0;
      } else {
        this.openSnackBar(posRes.message, "");
        this.pageNo = this.pageNo - 1;
        this.isFetchingUsers = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingUsers = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }



  searchByName() {
    if (this.searchByNameForm.valid) {
      this.filterdCustomers = []
      this.pageNo = 1;
      this.isSearchByName = true;
      this.getUsersByName();
    } else {
      this.fetchAllUsers();
      //this.openSnackBar("Enter First Name..", "")
    }
  }
  NotUpdatesearchByName(){
    if (this.searchByNameForm1.valid) {
      this.filterdCustomers = []
      this.pageNo = 1;
      this.isSearchByName = true;
      this.getNotUsersByName();
    } else {
      this.filterdCustomers = []
      this.pageNo = 1;
      this.isSearchByName = false;
      this.ProfileNotUpdateUser();
      //this.openSnackBar("Enter First Name..", "")
    }
  }
  getNotUsersByName(){
    this.adminService.showLoader.next(true);
    this.message = 'Finding Users..'
    this.isFetchingUsers = true;
    this.searchByNameForm1.patchValue({
      pageNo: "" + this.pageNo
    })
    this.isSearchByName = true;
    let token = sessionStorage.getItem('token');
    this.adminService.searchByName(this.searchByNameForm1.value, token).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.pageNumbers = [];
      this.adminService.showLoader.next(false);
      this.message = "No Users Found.."
      if (posRes.response == 3) {
        this.customers = posRes.FetchData;
        if(this.customers.length > 0){
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          if (this.totalPageCount <= this.pageNo) {
            this.isTotalCountReached = true;
          } 
          else {
            this.isTotalCountReached = false;
          }       
          this.filterdCustomers = this.customers;
          // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
          this.isFetchingUsers = false;
        }else{
          this.isTotalCountReached = true;
          this.totalPageCount = 1;
          this.filterdCustomers = [];
        }
      } else {
        this.openSnackBar(posRes.message, "")
        this.pageNo = this.pageNo - 1;
        this.isFetchingUsers = false;        
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingUsers = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  getUsersByName() {
    this.adminService.showLoader.next(true);
    this.message = 'Finding Users..'
    this.isFetchingUsers = true;
    this.searchByNameForm.patchValue({
      pageNo: "" + this.pageNo,
      type: "Users"
    })
    this.isSearchByName = true;
    let token = sessionStorage.getItem('token');
    let payload = { ...this.searchByNameForm.value };
    payload.type="Users";
    // console.log(this.searchByNameForm.value);
    this.adminService.searchByName(payload, token).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.pageNumbers = [];
      this.adminService.showLoader.next(false);
      this.message = "No Users Found.."
      if (posRes.response == 3) {
        this.customers = posRes.FetchData;
        if(this.customers.length > 0){
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          if (this.totalPageCount <= this.pageNo) {
            this.isTotalCountReached = true;
          } 
          else {
            this.isTotalCountReached = false;
          }       
          this.filterdCustomers = this.customers;
          // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
          this.isFetchingUsers = false;
        }else{
          this.totalPageCount = 1;
          
          this.filterdCustomers = this.customers;
          this.isTotalCountReached = true;
        }
        
      
      } else {
        this.openSnackBar(posRes.message, "")
        this.pageNo = this.pageNo - 1;
        this.isFetchingUsers = false;        
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingUsers = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  getFilteredTask() {
    if (this.filterForm.value.dateRange != null) {
      // console.log(new Date(this.filterForm.value.dateRange[0]).setHours(0, 0, 0, 0), new Date(this.filterForm.value.dateRange[1]).setHours(23, 59, 59, 100));

      let frmDate = new Date(this.filterForm.value.dateRange[0]).getTime();
      let toDate = new Date(this.filterForm.value.dateRange[1]).getTime();
      frmDate = new Date(this.filterForm.value.dateRange[0]).setHours(0, 0, 0, 0);
      toDate = new Date(this.filterForm.value.dateRange[1]).setHours(23, 59, 59, 999);
      this.filterdCustomers = [];
      this.pageNo = 1;
      this.customerObj = {
        type: this.filtercustomer,
        "sortBy": this.filterOption,
        pageNo: "" + this.pageNo,
        size: "60",
        "fromdate": "" + frmDate,
        "todate": "" + toDate,
      }
      this.fetchAllUsers();
    } else {
      this.filterdCustomers = [];
      this.pageNo = 1;
      this.customerObj = {
        type: this.filtercustomer,
        "sortBy": this.filterOption,
        pageNo: "" + this.pageNo,
        size: "90"
      }
      this.fetchAllUsers()
    }
  }
  taskFilters() {
    let obj = {
      from: "AllCust",
      category: this.categoryList,
      filters: this.divineCustomerObj
    }
    this.pageNo = 1;
    let dialogRef = this.dialog.open(FiltersdevineComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: obj
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.dateFilter) {
        this.filterdCustomers = [];
        this.divineDateFilter = true;
        this.divinePageNo = 1;
        this.divineCustomerObj = {
          pageNo:""+this.divinePageNo,
          size:this.divineSize,
          fromdate:""+ res.fromdate,
          todate:""+res.todate
        }
        console.log(this.divineCustomerObj);
        this.getDateDivineFiltersData();
      }else{
        this.divineDateFilter = false;
        this.divinePageNo = 1;
        this.fetchAllDivineUsers();
      }
    })
  }
  changeView(event) {
    // console.log(event.value);
    if (event.value == "ProfileNotUpdate") {
      this.UserTypes = "ProfileNotUpdate"; 
      this.isProfileNotUpdate = true;
      this.ProfileNotUpdateUser();
    }else{
      this.pageNo =1;
      this.UserTypes == "ProfileUpdate";
      this.isProfileNotUpdate = false;
      this.fetchAllUsers()
    }
  }
  gotoSelectedPage(num) {
    this.pageNo = num;
    if (!this.isSearchByName) {
      this.fetchAllUsers()
    } else {
      this.getUsersByName()
    }
  }
  gotoSelectedPage1(num) {
    this.pageNo = num;
    if (!this.isSearchByName) {
      this.ProfileNotUpdateUserNext_prev()
    } else {
      this.ProfileNotUpdateUser()
    }
  }
  ProfileNotUpdateUserNext_prev() {
    this.pageNumbers = [];
    this.message = "Finding Users.."
    this.isSearchByName = false;
    this.isFetchingUsers = true;
    this.customerObj.pageNo = "1";
    this.customers = [];
    this.adminService.showLoader.next(true);
    let token = sessionStorage.getItem('token');
    let obj = {
      "pageNo": ""+this.pageNo,
      "size": "20",
      "type": "All",
      "State": "All",
      "category": "All",
      "sortBy": "All"
    }
    // console.log(obj);
    this.filterdCustomers = [];
    this.customerSubscribe = this.adminService.fetchNotProfileUpdateCustomers(obj, token).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.message = "No Users Found.."
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.customers = posRes.FetchData;
        this.totalPageCount = posRes.pages;
        for (let i: number = 0; i < this.totalPageCount; i++) {
          this.pageNumbers.push(i + 1);
        }
        if (this.totalPageCount <= this.pageNo) {
          this.isTotalCountReached = true;
        } else {
          this.isTotalCountReached = false;
        }
        // let parentElm = document.getElementById('main-wrap1');
        //let  currentScrollPositio = parentElm.pageYOffset;
       // let currentScrollPosition = parentElm.scrollTop;
        //// console.log("Yposition",currentScrollPosition);
        // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
        this.filterdCustomers = this.customers
        this.isFetchingUsers = false;
        //  parentElm.scrollTop = currentScrollPosition;
       // parentElm.scrollTop = 0;
      } else {
        this.openSnackBar(posRes.message, "");
        this.pageNo = this.pageNo - 1;
        this.isFetchingUsers = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingUsers = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  receiveMessage(event) {
    this.isDetails = event;
    this.allcustomer = true;
  }
  showUserDetails(customer) {
    // console.log(customer);
    this.adminService.showLoader.next(true);
    this.isDetails = true;
    this.customerID = customer.PhoneNumber;
    this.allcustomer =false;
    // this.router.navigate(['admin','customer',customer.userID]);
  }
  showDivineUserDetails(customer){
    this.adminService.showLoader.next(true);
    this.isDetails = true;
    this.customerID = customer.PhoneNumber;
    this.allcustomer =false;
  }
  loadMore() {
    this.pageNo = this.pageNo + 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.fetchAllUsers()
    } else {
      this.getUsersByName()
    }
  }
  nextPageDivine(){
    if(this.divineDateFilter){
      this.divinePageNo = this.divinePageNo + 1;
      this.divineCustomerObj["pageNo"]=""+this.divinePageNo;
      this.getDateDivineFiltersData();
    }else{
      this.divinePageNo = this.divinePageNo + 1;
      this.fetchAllDivineUsers();
    }
    
  }
  previousDivinePage(){
    if(this.divineDateFilter){
      this.divinePageNo = this.divinePageNo - 1;
      this.divineCustomerObj["pageNo"]=""+this.divinePageNo;
      this.getDateDivineFiltersData();
    }else{
      this.divinePageNo = this.divinePageNo - 1;
      this.fetchAllDivineUsers();
    }
   
  }
  gotoSelectedDevinePage(num){
    if(this.divineDateFilter){
      this.divineCustomerObj["pageNo"]=""+num;
      this.getDateDivineFiltersData();
    }else{
      this.divinePageNo = num;
      this.fetchAllDivineUsers();
    }
    
  }
  loadMore1() {
    // console.log(this.pageNo);
    this.pageNo = this.pageNo + 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.ProfileNotUpdateUserNext_prev()
    } else {
      this.getNotUsersByName()
    }
  }
  previousPage() {
    this.pageNo = this.pageNo - 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.fetchAllUsers()
    } else {
      this.getUsersByName()
    }
  }
  previousPage1() {
    this.pageNo = this.pageNo - 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.ProfileNotUpdateUserNext_prev();
    } else {
      this.getNotUsersByName()
    }
  }
  fetchAllUsers() {
    this.searchByNameForm = this.fb.group({
      userID: ["", Validators.required],
      pageNo: [""],
      size: ["21"]
    })
    this.pageNumbers = [];
    this.message = "Finding Users.."
    this.isSearchByName = false;
    this.isFetchingUsers = true;
    this.customerObj.pageNo = "" + this.pageNo;
    this.adminService.showLoader.next(true);
    let token = sessionStorage.getItem('token');
    this.filterdCustomers =[];
    // console.log(this.customerObj);
    this.customerSubscribe = this.adminService.fetchAllCustomers(this.customerObj, token).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.message = "No Users Found.."
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.customers = posRes.FetchData;
        this.totalPageCount = posRes.pages;
        for (let i: number = 0; i < this.totalPageCount; i++) {
          this.pageNumbers.push(i + 1);
        }
        if (this.totalPageCount <= this.pageNo) {
          this.isTotalCountReached = true;
        } else {
          this.isTotalCountReached = false;
        }
       // let parentElm = document.getElementById('main-wrap');
        //let  currentScrollPositio = parentElm.pageYOffset;
       //  let currentScrollPosition = parentElm.scrollTop;
        //// console.log("Yposition",currentScrollPosition);
        // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
        this.filterdCustomers = this.customers
        this.isFetchingUsers = false;
        //  parentElm.scrollTop = currentScrollPosition;
        // parentElm.scrollTop = 0;
      } else {
        this.openSnackBar(posRes.message, "");
        this.pageNo = this.pageNo - 1;
        this.isFetchingUsers = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingUsers = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  showAll() {
    this.filterdCustomers = this.customers;
  }
  showOnly(text) {

    this.filterOption = text

  }
  applyFilter(term: string) {
    if (!term) {
      this.filterdCustomers = this.customers;
    } else {
      this.filterdCustomers = this.customers.filter(x =>
        x.firstName.trim().toLowerCase().includes(term.trim().toLowerCase())
      );
    }

  }
  ngOnDestroy() {
    this.customerSubscribe.unsubscribe();
  }
}
