import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AdminService } from '../../admin.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent implements OnInit {
  baseUrl :string ="";
  id : string ="";
  isFromAllOrders : boolean = false;
  routeSub: any;
  orderInfo : any;
  @Input() childID: string;
  @Output() closeEvent = new EventEmitter();
  constructor(
    private adminService : AdminService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.baseUrl = this.adminService.baseUrl;
    this.onLoad();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.id = this.childID;
    this.isFromAllOrders = true
    // this.fetchData();
    this.fetchOrderData();
  }
  onLoad() {
    this.baseUrl = this.adminService.baseUrl
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      //log the entire params object
      if (params && params['id']) {
        this.id = params['id'];
        // this.fetchData()
        this.fetchOrderData();
      }
    });
  }
  fetchOrderData(){
    this.adminService.showLoader.next(true);
    let id = { orderid:this.id }
    let token = sessionStorage.getItem('token')
    this.adminService.fetchOrderDetails(id,token).subscribe((posRes) => {
      // console.log("PosREs ", posRes);

      if (posRes.response == 3) {
        this.orderInfo = posRes.Info
        this.adminService.showLoader.next(false);
        //this.changeView(event);
      } else {
        this.adminService.showLoader.next(false);
        alert(posRes.message);
      }
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        // console.log("CSE", err.message);
      } else {
        // console.log("SSE", err.message);
      }
    })
  }
  closeDetails(){
    this.isFromAllOrders = false;
    this.closeEvent.emit(false);
  }

}
