import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public baseUrl: string = "https://api.nutrifyfoods.com";
  stagingUrl: string = "https://api.nutrifyfoods.com"
  showLoader = new BehaviorSubject(false);
  public categoriesList: any = {};
  constructor(private http: HttpClient) { }

  adminLogin(data) {
    return this.http.post(`${this.baseUrl}/api/admin/login`, data)
  }
  updateMobileNumber(data, token): Observable<any> {
    return this.http.put("https://api.nutrifyfoods.com/api/customer/updatemobileno", data, { headers: { "softexer": token } })
  }
  // Dash Board Api's
  fetchStatus(token): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/admin/QuickStatus`, { headers: { "softexer": token } });
  }
  fetchBookingStatus(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/totalBookings`, data, { headers: { "softexer": token } });
  }
  fetchCategoryStatus(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/postjobsFilter`, data, { headers: { "softexer": token } })
  }
  fetchlocationBasedTasks(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/getStateWiseJobs`, data, { headers: { "softexer": token } })
  }
  browsBookings(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/postjob/fetch_all`);
  }
  searchByName(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/namesearching`, data, { headers: { "softexer": token } })
  }
  releasePaymentToProvider(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/status`, data, { headers: { "softexer": token } })
  }
  getMyTasks(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/postjob/get`, data, { headers: { "softexer": token } })
  }
  getMyProjects(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/projects/viewProject`, data, { headers: { "softexer": token } })
  }
  getMyBookings(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/fetchBuySellData`, data, { headers: { "softexer": token } })
  }
  getTasksBySearch(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/postjob_search`, data, { headers: { "softexer": token } })
  }
  getProjectBySearch(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/postproject_search`, data, { headers: { "softexer": token } })
  }
  getBuyProjectBySearch(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/buyproject_search`, data, { headers: { "softexer": token } })
  }
  getBookingsBySearch(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/bookings_search`, data, { headers: { "softexer": token } })

  }
  deleteTask(data, token) {
    return this.http.post(`${this.baseUrl}/api/admin/deletetask`, data, { headers: { "softexer": token } })
  }
  getFilteredTasks(data, token) {
    return this.http.post(`${this.baseUrl}/api/admin/fetchtask`, data, { headers: { "softexer": token } })
  }
  getFilteredPurchaseProjects(data, token) {
    return this.http.post(`${this.baseUrl}/api/admin/purchaseProjects`, data, { headers: { "softexer": token } })
  }
  getPayments(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/AllPaymetsStatus/`, data)
  }
  getPaymentsData(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/getPaymentData/`, data)
  }
  PaymnetStatus(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/projectPaymentSuccessorRejectNotify`, data)
  }
  PaymentTableData(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/totalAmountManualPayments`, data)
  }
  SearchManualPayment(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/SearchManualPayment`, data)
  }
  DateRangePayments(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/DateRangePayments`, data)
  }
  addProject(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/projects/addProject", data, {
      headers: { softexer: token },
    });
  }
  updateProject(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/projects/updateProject", data, {
      headers: { softexer: token },
    });
  }
  onlinePayments(data,token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/onlinePayments/`, data, {
      headers: { softexer: token },
    });
  }
  //  Hire Providers
  hireProviders(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/hire/taskers`, data, { headers: { "softexer": token } })
  }
  browseCategory(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/categories`)
  }
  bookingDetails(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/hire/`, data, { headers: { "softexer": token } })
  }
  ediComment(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/updatecomment`, data, { headers: { "softexer": token } });
  }
  fetchAllbookings(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/bookings`, data, { headers: { "softexer": token } })
  }
  fetchAllProjectbookings(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/hire/fetchBuyandSellData`, data, { headers: { "softexer": token } })
  }
  fetchAllCustomers(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/filters`, data, { headers: { "softexer": token } })
  }
  fetchNotProfileUpdateCustomers(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/notupdatedfilter`, data, { headers: { "softexer": token } })
  }
  downloadReports(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/Reports`, data, { headers: { "softexer": token }, responseType: 'arraybuffer' })
  }
  deleteComments(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/deletecomment`, data, { headers: { "softexer": token } })
  }
  deleteOffer(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/deleteprovidertask`, data, { headers: { "softexer": token } })
  }
  // get details with ratings also;
  fetchUserDetails(id, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/`, id, { headers: { "softexer": token } })
  }
  // get only details.Api from apps
  getUserDetails(id): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/fetch`, id)
  }
  // Block or unblock

  blockUnBlock(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/blockOrUnblock`, data, { headers: { "softexer": token } })
  }
  getMyOfferedTasks(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/offers/getOfferedPosts`, data, { headers: { "softexer": token } })
  }
  bookingwithDrawDetails(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/withdrawbookings`, data, { headers: { "softexer": token } })
  }
  // Account verification
  verifyCustomer(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/verification`, data, { headers: { "softexer": token } })
  }
  // Refferal
  getReferralData(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/referral`, data, { headers: { "softexer": token } })
  }
  fetchUserReffaral(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/fetchEarnedReferrals`, data, { headers: { "softexer": token } })
  }
  makeAsPaid(obj, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/referralpaid`, obj, { headers: { "softexer": token } })
  }
  // Chat Update
  updateChatMessages(obj, token): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/postjob/offer_Chat_Update`, obj, { headers: { "softexer": token } })
  }
  // Delete chat messages
  deleteChatMessages(obj, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "softexer": token
      }),
      body: {
        "postID": obj.postID,
        "offeredUserID": obj.offeredUserID,
        "userID": obj.userID,
        "message": obj.message,
        "timeStamp": obj.timeStamp
      }
    }
    return this.http.delete(`${this.baseUrl}/api/postjob/chatDelete`, options)
  }
  fetchNotifications(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/fetchAdminNotifications`, data, { headers: { "softexer": token } })
  }
  fetchUserIDS(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/fetchCustomerIDs`, data, { headers: { "softexer": token } })
  }
  sendNotifications(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/pushnotification`, data, { headers: { "softexer": token } })
  }
  deleteNotifiCation(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "softexer": token
      }),
      body: {
        "notifyID": data.notifyID,
        userID: data.userID,
        type: data.type
      }
    }
    return this.http.delete(`${this.baseUrl}/api/admin/deleteNotification`, options)
  }
  deleteAllNotifiCations(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "softexer": token
      }),
      body: {
        userID: data.userID,
        type: data.type
      }
    }
    return this.http.delete(`${this.baseUrl}/api/admin/deleteAllNotification`, options)
  }
  settingsCategoryInsert(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/categories/insert`, data, { headers: { "softexer": token } })
  }
  advatageInsert(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/categories/insertadvatage`, data, { headers: { "softexer": token } })
  }
  updateAmount(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/categories/updateamountdata`, data, { headers: { "softexer": token } })
  }
  deleteCategory(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "softexer": token
      }),
      body: {
        "categoryId": data.categoryId
      }
    }
    return this.http.delete(`${this.baseUrl}/api/categories/delete`, options)
  }
  deleteAdvatage(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "softexer": token
      }),
      body: {
        "categoryId": data.categoryId
      }
    }
    return this.http.delete(`${this.baseUrl}/api/categories/deleteadvatage`, options)
  }

  fetchAllDivineOrders(data) : Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/ordersfetch`, data)
  }
  fetchOrderDetails(data,token) : Observable<any> {
    return this.http.post(`${this.baseUrl}/api/orders/singleorderfetch`, data,{ headers: { "divine": token }})
  }
  devineDashbord(token)  : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "divine": token
      })
    }
    return this.http.get(`${this.baseUrl}/api/admin/DashboardData`, options)
  }
  downloadDivineReports(data: any,token){
    return this.http.post(`${this.baseUrl}/api/admin/Reports`, data,{ headers: { "divine": token }, responseType: 'arraybuffer'});
  }
  getAllCategories(data: any){
    return this.http.post(`${this.baseUrl}/api/categories/subcategoryfetch`, data);
  }
  insertCategory(data: any,token){
    return this.http.post(`${this.baseUrl}/api/categories/categoryinsert`, data,{ headers: { "divine": token }});
  }
  insertsubCategory(data: any,token){
    return this.http.put(`${this.baseUrl}/api/categories/subcategoryinsert`, data,{ headers: { "divine": token }});
  }
  updateCategory(data: any,token){
    return this.http.put(`${this.baseUrl}/api/categories/categoryupdate`, data,{ headers: { "divine": token }});
  }
  DeleteCategories(data: any,token){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "divine": token
      }),
      body: {
        "type": data.type
      }
    }
    return this.http.delete(`${this.baseUrl}/api/categories/categoriesDelete`, options)
    //return this.http.delete(`${this.baseUrl}/api/categories/categoriesDelete`, data,{ headers: { "divine": token }});
  }
  updatesubCategory(data: any,token){
    return this.http.put(`${this.baseUrl}/api/categories/subcategoryupdate`, data,{ headers: { "divine": token }});
  }
  AddProducts(data: any,token){
    return this.http.post(`${this.baseUrl}/api/sellerProducts/productinsert`, data,{ headers: { "divine": token }});
  }
  getAllProducts(data: any){
    return this.http.post(`${this.baseUrl}/api/sellerProducts/productfetch`, data);
  }
  updateProducts(data: any,token){
    return this.http.put(`${this.baseUrl}/api/sellerProducts/productupdate`, data,{ headers: { "divine": token }});
  }
  fetchOrdersStatus(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/Admingraph`, data, { headers: { "divine": token } });
  }
  SearchCategories(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/categories/categorysearch`, data, { headers: { "divine": token } });
  }
  getSearchSubCategories(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/categories/subcategorysearch`, data, { headers: { "divine": token } });
  }
  DeletesubCategories(data: any,token){
    return this.http.put(`${this.baseUrl}/api/categories/subcategorypulldata`, data,{ headers: { "divine": token }});
  }
  getSearchProductCategories(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/categories/productsearch`, data, { headers: { "divine": token } });
  }
  DeleteProducts(data: any,token){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "divine": token
      }),
      body: {
        "type": data.type
      }
    }
    return this.http.delete(`${this.baseUrl}/api/sellerProducts/productdelete`, options)
  }
  divineonlinePayments(data, token): Observable<any> {
    console.log(data)
    return this.http.post(`${this.baseUrl}/api/admin/onlinePayments`, data, { headers: { "divine": token } });
  }
  dispatchDivineOrders(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/orderDispatch`, data, { headers: { "divine": token } });
  }
  phoneperefund(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/orders/PhonePayPaymentRefund`, data);
  }
}
