import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-divinereports',
  templateUrl: './divinereports.component.html',
  styleUrls: ['./divinereports.component.css']
})
export class DivinereportsComponent implements OnInit {
  maxDate1: any = new Date();
  providerForm:any = FormGroup;
  referraluserForm :any= FormGroup;
  bsInlineRangeValue: Date[] | undefined;
  bookingsForm:any= FormGroup;
  bookingStatus: string = "All";
  fileName: string = "";
  constructor(
    private adminService: AdminService, 
    private dialog: MatDialog,
    private fb: FormBuilder, 
    private snackBar: MatSnackBar, 
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter, 
    private sanitizer: DomSanitizer, 
    private router: Router,
  ) { }

  ngOnInit() {
    this.providerForm = this.fb.group({
      dateRange: ["", Validators.required]
    })
    this.referraluserForm = this.fb.group({
      dateRange: ["", Validators.required]
    })
    this.bookingsForm = this.fb.group({
      dateRange: ["", Validators.required]
    })
  }

  getproviderDetails(){
    let frmDate = new Date(this.providerForm.value.dateRange[0]).getTime();
    let toDate = new Date(this.providerForm.value.dateRange[1]).getTime();

    frmDate = new Date(this.providerForm.value.dateRange[0]).setHours(0, 0, 0, 0);
    toDate = new Date(this.providerForm.value.dateRange[1]).setHours(23, 59, 59, 999);
    let obj = {
      reportType: "Customer",
      fromDate: "" + frmDate,
      toDate: "" + toDate
    }
    this.fileName = new Date(this.providerForm.value.dateRange[0]).toLocaleDateString() + "-" + new Date(this.providerForm.value.dateRange[1]).toLocaleDateString() + "divineOilUsers_"+".csv";
    this.getReports(obj)
  }
  getBookings(){
    let frmDate = new Date(this.bookingsForm.value.dateRange[0]).getTime();
    let toDate = new Date(this.bookingsForm.value.dateRange[1]).getTime();

    frmDate = new Date(this.bookingsForm.value.dateRange[0]).setHours(0, 0, 0, 0);
    toDate = new Date(this.bookingsForm.value.dateRange[1]).setHours(23, 59, 59, 999);
    let obj = {
      reportType: "Payments",
      fromDate: "" + frmDate,
      toDate: "" + toDate
    }
    this.fileName = new Date(this.bookingsForm.value.dateRange[0]).toLocaleDateString() + "-" + new Date(this.bookingsForm.value.dateRange[1]).toLocaleDateString() + "PaymentUsers_"+".csv";
    this.getReports(obj)
  }
  getFilteredreferralUsersReport1(){
    let frmDate = new Date(this.referraluserForm.value.dateRange[0]).getTime();
    let toDate = new Date(this.referraluserForm.value.dateRange[1]).getTime();

    frmDate = new Date(this.referraluserForm.value.dateRange[0]).setHours(0, 0, 0, 0);
    toDate = new Date(this.referraluserForm.value.dateRange[1]).setHours(23, 59, 59, 999);
    let obj = {
      reportType: "Orders",
      fromDate: "" + frmDate,
      toDate: "" + toDate
    }
    this.fileName = new Date(this.referraluserForm.value.dateRange[0]).toLocaleDateString() + "-" + new Date(this.referraluserForm.value.dateRange[1]).toLocaleDateString() + "ReferralUsers_"+".csv";
    this.getReports(obj)
  }
 
 
  getReports(obj:any) {
    this.adminService.showLoader.next(true);
    let token = sessionStorage.getItem('token');
    console.log(obj);
    this.adminService.downloadDivineReports(obj,token).subscribe((posRes:any) => {
      console.log(posRes);
      this.adminService.showLoader.next(false);
      var blob = new Blob([posRes]);
      let newVariable: any = window.navigator;
      if (newVariable.msSaveOrOpenBlob) {
        newVariable.msSaveBlob(blob, this.fileName);
      }
      else {
        var a = window.document.createElement("a");

        a.href = window.URL.createObjectURL(blob);
        a.download = this.fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.warn("Client Error", err.message);
      } else {
        console.warn("Server Error", err.message);
      }
    })
  }

}
