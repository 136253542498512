import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from './admin.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSidenav } from "@angular/material/sidenav";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'DivineAdmin';
  loading: boolean = false;
  isLoggedIn = false;
  isUrl: string = "";
  Findurl: boolean = false;
  mybutton: any;
  token: any;
  @ViewChild("sidenav", { static: true }) sideNav!: MatSidenav;
  @ViewChild("sideNavContainer", { static: true }) sideNavContainer!: ElementRef;
  constructor(
    private adminService: AdminService, 
    private cd: ChangeDetectorRef, 
    private bnIdle: BnNgIdleService, 
    private router: Router
  ) {
    this.adminService.showLoader.subscribe((flag: boolean) => {
      if (this.loading !== flag) {
        this.loading = flag;
      }
    })
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (evt instanceof NavigationEnd) {
        this.isUrl = "" + evt.url;
        if (this.isUrl != '/login') {
          this.Findurl = true;
        } else {
          this.Findurl = false;
        }
      }
      window.scrollTo(0, 0);
    });
  }
  ngOnInit() {
    this.bnIdle.startWatching(60 * 60).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        if ((this.router.url != '/login')) {
          sessionStorage.clear();
          this.router.navigateByUrl('/login');
        }

      }
    });
  }
  scrollCheck() {
    this.mybutton = document.getElementById("myBtn");
    if (
      document.body.scrollTop > 300 ||
      document.documentElement.scrollTop > 300
    ) {
      this.mybutton.style.opacity = "1";
    } else {
      this.mybutton.style.opacity = "0";
    }
  }
  toggleSideNav() {
    this.sideNav.toggle();
    setTimeout(() => {
      // const sideNavContainer = document.getElementById('sideNavContainer');
      // if (sideNavContainer && sideNavContainer.classList.contains('mat-drawer-container-has-open')) {
      //   document.body.style.overflow = 'hidden';
      // } else {
      //   document.body.style.overflow = 'auto';
      // }
    }, 2000);
  }
  closedStart() {
    document.body.style.overflow = "auto";
  }
  openedStart() {
    document.body.style.overflow = "hidden";
  }
  scroll() {
    window.scroll(0, 0);
  }
  toggle() {
    this.sideNav.toggle();
  }
  logOut(){
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login')
  }
}
