import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddproductComponent } from '../addproduct/addproduct.component';
import { DeleteproductsComponent } from '../deleteproducts/deleteproducts.component';
 
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  pageNo: number = 1;
  totalPageCount: number = 1;
  pageNumbers: Array<any> = [1,2,3,4,5];
  searchBysubCategoryName : any = FormGroup;
  baseurl : string="";
  AllCategoryInfo : any;
  AllSubCategoryInfo : any;
  AllProductInfo :Array<any>=[];
  AllCategoryNames : any;
  selectedSubCat : string = "All";
  selectedCat : string = "All";
  constructor(
    private adminSerivce : AdminService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.baseurl = this.adminSerivce.baseUrl;
    this.searchBysubCategoryName = this.fb.group({
      searchText : ["", Validators.required]
    });
    this.getCategories();
  }

  getCategories(){
    let obj={
      type:"All"
    }
    this.adminSerivce.getAllCategories(obj).subscribe(
      (posRes:any) => {
        if (posRes.response == 3) {
          // this.adminSerivce.showLoader.next(false);
          this.AllCategoryInfo = posRes.CategoryInfo;
          this.getAllproducts();
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  getAllproducts(){
    let obj={
      type:"All"
    }
    this.adminSerivce.showLoader.next(true);
    this.adminSerivce.getAllProducts(obj).subscribe(
      (posRes:any) => {
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          this.AllProductInfo = posRes.Info;
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  getCateproducts(catID){
    this.adminSerivce.showLoader.next(true);
    console.log(catID);
    let obj={
      type:catID
    }
    console.log(obj);
    this.adminSerivce.getAllProducts(obj).subscribe(
      (posRes:any) => {
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          this.AllProductInfo = posRes.Info;
        } else {
          this.adminSerivce.showLoader.next(false);
          this.AllProductInfo = [];
          //this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  AddnewProduct(){
    let dialogRef = this.dialog.open(AddproductComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "add",data : null,cate:this.selectedCat,subcate:this.selectedSubCat}
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res.apicall){
        if(this.selectedSubCat != 'All'){
          let catid = "";
          this.AllSubCategoryInfo.filter((item)=>{
            if(item.subCategoryName == this.selectedSubCat){
              catid = item.subCategoryID
            }
          })
          this.getCateproducts(catid);
        }else{
          if(this.selectedCat == 'All'){
            this.getCateproducts("All");
          }else{
            let catid = ""
            this.AllCategoryInfo.filter((item)=>{
              if(item.categoryName == this.selectedCat){
                catid = item.categoryID
              }
            })
            this.getCateproducts(catid);
          }
        }
      }
    })
  }
  productView(product){
    let dialogRef = this.dialog.open(AddproductComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "view",data : product}
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res.apicall){
        if(this.selectedSubCat != 'All'){
          let catid = "";
          this.AllSubCategoryInfo.filter((item)=>{
            if(item.subCategoryName == this.selectedSubCat){
              catid = item.subCategoryID
            }
          })
          this.getCateproducts(catid);
        }else{
          if(this.selectedCat == 'All'){
            this.getCateproducts("All");
          }else{
            let catid = ""
            this.AllCategoryInfo.filter((item)=>{
              if(item.categoryName == this.selectedCat){
                catid = item.categoryID
              }
            })
            this.getCateproducts(catid);
          }
        }
      }
    })
  }
  productEdit(product){
    let dialogRef = this.dialog.open(AddproductComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "edit",data : product}
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res.apicall){
        if(this.selectedSubCat != 'All'){
          let catid = "";
          this.AllSubCategoryInfo.filter((item)=>{
            if(item.subCategoryName == this.selectedSubCat){
              catid = item.subCategoryID
            }
          })
          this.getCateproducts(catid);
        }else{
          if(this.selectedSubCat == 'All'){
            if(this.selectedCat == 'All'){
              this.getCateproducts("All");
            }else{
              let catid = ""
              this.AllCategoryInfo.filter((item)=>{
                if(item.categoryName == this.selectedCat){
                  catid = item.categoryID
                }
              })
              this.getCateproducts(catid);
            }
          }
          // if(this.selectedCat == 'All'){
          //   this.getCateproducts("All");
          // }else{
          //   let catid = ""
          //   this.AllSubCategoryInfo.filter((item)=>{
          //     if(item.categoryName == this.selectedCat){
          //       catid = item.categoryID
          //     }
          //   })
          //   this.getCateproducts(catid);
          // }
        }
      }
    })
  }
  searchSubCategory(){
    let payload = { ...this.searchBysubCategoryName.value };
    let catid="";
    let subcatid="";
    if(this.selectedCat !='All'){
      this.AllCategoryInfo.filter((item)=>{
        if(item.categoryName == this.selectedCat){
          catid = item.categoryID;
        }
      })
    }else{
      catid = "All"
    }
    if(this.selectedSubCat != 'All'){
      this.AllSubCategoryInfo.filter((item)=>{
        if(item.subCategoryName == this.selectedSubCat){
          subcatid = item.subCategoryID
        }
      })
    }else{
      subcatid = "All"
    }
  
    if (payload.searchText.trim() != "") {
      let obj = {
        type:catid,
        text:payload.searchText,
        subcategoryID:subcatid
      }
      let token = sessionStorage.getItem('token');
      this.adminSerivce.showLoader.next(true);
      this.adminSerivce.getSearchProductCategories(obj, token).subscribe(
        (posRes: any) => {
          if (posRes.response == 3) {
            this.adminSerivce.showLoader.next(false);
            this.AllProductInfo = posRes.productList;
          } else {
            this.adminSerivce.showLoader.next(false);
            this.AllProductInfo=[];
            //this.openSnackBar(posRes.message, "");
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.adminSerivce.showLoader.next(false);
            this.openSnackBar(err.message, "Client Error");
            console.log(err.message);
          } else {
            this.openSnackBar(err.message, "Server Error");
            console.log(err.message);
          }
        })
    }else{
      this.getCateproducts(catid);
    }
  }
  onSelectedCategory(){
    this.searchBysubCategoryName.patchValue({
      searchText:""
    })
    if(this.selectedCat == 'All'){
      this.AllSubCategoryInfo = [];
      this.selectedSubCat = "All";
      this.getCateproducts(this.selectedCat);
    }else{
      let catid="";
      this.AllCategoryInfo.filter((item)=>{
        if(item.categoryName == this.selectedCat){
          catid = item.categoryID;
          this.AllSubCategoryInfo = item.subCategorys
        }
      })
      this.getCateproducts(catid);
    }
  }
  productDelete(product:any){
    let dialogRef = this.dialog.open(DeleteproductsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : {option : "deleteProduct",data : product}
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res.isDelete) {
        this.deletepProduct(res.deleteID);
        // this.pageNo = 1;
      }
    })
  }
  deletepProduct(pid){
    let obj={
      type:pid
    }
    let token = sessionStorage.getItem('token');
    this.adminSerivce.showLoader.next(true);
    this.adminSerivce.DeleteProducts(obj,token).subscribe(
      (posRes:any) => {
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          // this.getAllproducts();
          if(this.selectedSubCat != 'All'){
            let catid = "";
            this.AllSubCategoryInfo.filter((item)=>{
              if(item.subCategoryName == this.selectedSubCat){
                catid = item.subCategoryID
              }
            })
            this.getCateproducts(catid);
          }else{
            if(this.selectedCat == 'All'){
              this.getCateproducts("All");
            }else{
              let catid = ""
              this.AllCategoryInfo.filter((item)=>{
                if(item.categoryName == this.selectedCat){
                  catid = item.categoryID
                }
              })
              this.getCateproducts(catid);
            }
          }
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
          if(this.selectedSubCat != 'All'){
            let catid = "";
            this.AllSubCategoryInfo.filter((item)=>{
              if(item.subCategoryName == this.selectedSubCat){
                catid = item.subCategoryID
              }
            })
            this.getCateproducts(catid);
          }else{
            if(this.selectedCat == 'All'){
              this.getCateproducts("All");
            }else{
              let catid = ""
              this.AllSubCategoryInfo.filter((item)=>{
                if(item.categoryName == this.selectedCat){
                  catid = item.categoryID
                }
              })
              this.getCateproducts(catid);
            }
          }
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  onSelectedSubCategory(){
    this.searchBysubCategoryName.patchValue({
      searchText:""
    })
    if(this.selectedSubCat == 'All'){
      if(this.selectedCat == "All"){
        this.getCateproducts("All");
      }else{
        let catid = ""
        this.AllCategoryInfo.filter((item)=>{
          if(item.categoryName == this.selectedCat){
            catid = item.categoryID
          }
        })
        this.getCateproducts(catid);
      }
    }else{
      let catid="";
        this.AllSubCategoryInfo.filter((item)=>{
          if(item.subCategoryName == this.selectedSubCat){
            catid = item.subCategoryID
          }
        })
        this.getCateproducts(catid);
    }
  }


   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
