import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlCustomersComponent } from '../al-customers/al-customers.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AdminService } from 'src/app/admin.service';

@Component({
  selector: 'app-filtersdevine',
  templateUrl: './filtersdevine.component.html',
  styleUrls: ['./filtersdevine.component.css']
})
export class FiltersdevineComponent implements OnInit {
  headings: string = "Users Filter";
  filterForm: FormGroup;
  category: string = "All";
  filtercustomer: string = "All";
  filterOption: string = "All";
  baseUrl: string = "";
  jobs: any = [];
  isAllCustmerFilters: boolean = false;
  isAllTasksFilters: boolean = false;
  isAllBookings: boolean = false;
  taskStatus: string = "All";
  bookingStatus: string = "All"
  customerObj: any;
  jobObj: any;
  state: string = "All";
  maxDate = new Date();
  categoryList: Array<any> = [];
  constructor(
    private dialogRef: MatDialogRef<AlCustomersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private fb: FormBuilder, 
    private adminService: AdminService) { }

  ngOnInit() {
    this.category = "All";
    // console.log(this.data);

    this.filterForm = this.fb.group({
      dateRange: [null]
    })
    this.onLoad();
  }
  onLoad() {
    this.categoryList = this.data.category;
    if (this.data.filters && this.data.filters.fromdate) {
      let dateFilter = this.data.filters;
      let dates = [new Date(parseInt(dateFilter.fromdate)), new Date(parseInt(dateFilter.todate))]
      this.filterForm.get('dateRange').setValue(dates)
    }
    if (this.data.from == "AllCust") {
      this.isAllCustmerFilters = true;
      this.isAllBookings = false;
      this.isAllTasksFilters = false;
      let filters = this.data.filters
      this.filterOption = filters.sortBy;
      this.filtercustomer = filters.type;
      this.category = filters.category;
      this.state = filters.State
    } else if (this.data.from == "AllTasks") {
      this.isAllTasksFilters = true;
      this.isAllCustmerFilters = false;
      this.isAllBookings = false;
      this.headings = "Projects filter";
      let filters = this.data.filters
      this.state = filters.State;
      this.taskStatus = filters.taskStatus;
      this.category = filters.category
    } else {
      this.headings = "Bookings filter";
      this.isAllCustmerFilters = false;
      this.isAllBookings = true;
      this.isAllTasksFilters = false;
      let filters = this.data.filters
      this.state = filters.State;
      this.bookingStatus = filters.type;
      this.category = filters.category

      // console.log("Cat", this.data);


    }
  }
  reset() {
    this.filterForm.get('dateRange').setValue(null)
    this.bookingStatus = "All";
    this.taskStatus = "All";
    this.category = "All";
    this.state = "All"
    this.filterOption = "All";
    this.filtercustomer = "All";
  }
  closeTab() {
    this.dialogRef.close();
  }
  getFilteredCustomers(){
    if (this.filterForm.value.dateRange != null) {
      let frmDate = new Date(this.filterForm.value.dateRange[0]).getTime();
      let toDate = new Date(this.filterForm.value.dateRange[1]).getTime();
      frmDate = new Date(this.filterForm.value.dateRange[0]).setHours(0, 0, 0, 0);
      toDate = new Date(this.filterForm.value.dateRange[1]).setHours(23, 59, 59, 999);
      this.customerObj = {
        fromdate: "" + frmDate,
        todate: "" + toDate,
        dateFilter : true
      }
      this.dialogRef.close(this.customerObj);
    }else{
      this.customerObj = {
        dateFilter : false
      }
      this.dialogRef.close(this.customerObj);
    }
  }
}
