import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CategoriesComponent } from '../categories/categories.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { imageValidator } from 'src/app/validators/validations';
import { AdminService } from '../../admin.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-deleteproducts',
  templateUrl: './deleteproducts.component.html',
  styleUrls: ['./deleteproducts.component.css']
})
export class DeleteproductsComponent implements OnInit {

  categroyName : string;
  dataInfo : any;
  deleteId : string;
  constructor(
    private dialogRef: MatDialogRef<CategoriesComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private adminSerivce : AdminService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.dataInfo = this.data;
    console.log(this.dataInfo);
    if(this.dataInfo.option == 'category'){
      this.categroyName = this.dataInfo.data.BrandName;
      this.deleteId = this.dataInfo.data.BrandID
    }
    if(this.dataInfo.option == 'subcategory'){
      this.categroyName = this.dataInfo.data.subCategoryName;
      this.deleteId = this.dataInfo.data.subCategoryID
    }
    if(this.dataInfo.option == 'deleteProduct'){
      this.categroyName = this.dataInfo.data.ProductName;
      this.deleteId = this.dataInfo.data.ProductID
    }
    
  }
  yesDlete(){
    let obj = {
      isDelete : true,
      deleteID: this.deleteId
    }
    this.dialogRef.close(obj);
  }
  noDelete(){
    let obj = {
      isDelete : false
    }
    this.dialogRef.close(obj);
  }

}
