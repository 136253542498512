import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CategoriesComponent } from '../categories/categories.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { imageValidator } from 'src/app/validators/validations';
import { AdminService } from '../../admin.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {

  previewUrl: any = "../../../assets/ionic-add-circle.png";
  addCategoryForm: FormGroup;
  loading: boolean = false;
  isReadOnly: boolean = false;
  isUpdateOnly: boolean = false;
  baseUrl: string = "";
  imgFile: File;
  options: string = "new";
  imgError :boolean = false;
  isLoading: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<CategoriesComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private adminSerivce: AdminService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.baseUrl = this.adminSerivce.baseUrl;
    this.addCategoryForm = this.fb.group({
      images: [null,Validators.required],
      categoryName: ["", Validators.required]
    })
    if (this.data.option == "view") {
      this.options = "view";
      this.isReadOnly = true;
      this.datapatchValues();
    }
    if (this.data.option == "edit") {
      this.options = "edit";
      this.isUpdateOnly = true;
      this.dataEditpatchValues();
    }
  }
  // Custom validator for image control
  imageValidators(control: AbstractControl): { [key: string]: any } | null {
    const imageValue = control.value;
    if (!imageValue || imageValue === "") {
      return { 'required': true, message: "Category image Required" };
    }
    // Check file type
    // const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    // console.log(imageValue.name);
    // if (!allowedExtensions.test(imageValue.name)) {
    //     return { invalidFileType: true, message: "Invalid file type. Only JPG, JPEG, PNG, and GIF files are allowed." };
    // }
    // You can add additional image validation logic here if needed.
    return null;
  }
  dataEditpatchValues() {
    let products = this.data.data;
    this.addCategoryForm.patchValue({
      categoryName: products.BrandName
    })
    this.previewUrl = this.baseUrl + products.Brandimage;
    this.http
      .get(this.previewUrl, { responseType: "blob" })
      .subscribe((file) => {
        let imgFile = new File([file], "userImg.jpg");
        this.addCategoryForm.get("images").setValue(imgFile);
      });
  }
  datapatchValues() {
    let products = this.data.data;
    this.addCategoryForm.patchValue({
      categoryName: products.BrandName
    })
    if (products.Brandimage !== "") {
      this.previewUrl = this.baseUrl + products.Brandimage;
    }
  }
 
  
  //File Upload

  fileProgress(event) {
    this.imgError = false;
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      // Check if the file has a valid extension (PNG or JPG)
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.indexOf(fileExtension) === -1) {
        // Invalid file type, you can handle this error as needed
        this.imgError = true;
        //console.error("Invalid file type. Only PNG and JPG files are allowed.");
        return;
      }else{
        reader.readAsDataURL(file);

        // When file uploads set it to file formcontrol
        reader.onload = () => {
          this.previewUrl = reader.result;
          this.addCategoryForm.get("images").setValue(file);
        };
        // ChangeDetectorRef since file is loading outside the zone
        this.cd.markForCheck();
      }
      
    }
  }
  closeTab() {
    this.dialogRef.close(false);
  }
  CategoryInsertInfo() {
    console.log(this.addCategoryForm.valid);
    console.log(this.addCategoryForm.value);
    if (this.addCategoryForm.valid) {
      if (this.options == "edit") {
        this.loading = true;
        this.isLoading = true;
        let payLoad = { ...this.addCategoryForm.value };
        delete payLoad.images;
        let formData = new FormData();
        let apiPayload = {
          categoryID: this.data.data.BrandID
        }
        formData.append("images", this.addCategoryForm.get("images").value);
        formData.append("updateCategoryInfo", JSON.stringify(apiPayload));
        let token = sessionStorage.getItem('token');
        if (this.addCategoryForm.value.images != "") {
          this.adminSerivce.showLoader.next(true);
          this.adminSerivce.updateCategory(formData, token).subscribe(
            (posRes: any) => {
              if (posRes.response == 3) {
                this.loading = false;
                this.isLoading = false;
                this.openSnackBar(posRes.message, "");
                this.dialogRef.close(true);
              } else {
                this.loading = false;
                this.isLoading = false;
                this.adminSerivce.showLoader.next(false);
                this.openSnackBar(posRes.message, "");
              }
            },
            (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                this.adminSerivce.showLoader.next(false);
                this.loading = false;
                this.openSnackBar(err.message, "Client Error");
              } else {
                this.loading = false;
                this.openSnackBar(err.message, "Server Error");
              }
            })

        } else {
          this.openSnackBar("Please upload category image", "");
        }

      } else {
        let payLoad = { ...this.addCategoryForm.value };
        delete payLoad.images;
        this.isLoading = true;
        let formData = new FormData();
        let apiPayload = {
          categoryName: payLoad.categoryName
        }
        formData.append("images", this.addCategoryForm.get("images").value);
        formData.append("categoryInfo", JSON.stringify(apiPayload));
        let token = sessionStorage.getItem('token');
        if (this.addCategoryForm.value.images != "") {
          this.loading = true;
          this.adminSerivce.showLoader.next(true);
          this.adminSerivce.insertCategory(formData, token).subscribe(
            (posRes: any) => {
              if (posRes.response == 3) {
                this.loading = false;
                this.isLoading = false;
                this.openSnackBar(posRes.message, "");
                this.dialogRef.close(true);
              } else {
                this.loading = false;
                this.isLoading = false;
                this.adminSerivce.showLoader.next(false);
                this.openSnackBar(posRes.message, "");
              }
            },
            (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                this.adminSerivce.showLoader.next(false);
                this.loading = false;
                this.openSnackBar(err.message, "Client Error");
              } else {
                this.loading = false;
                this.openSnackBar(err.message, "Server Error");
              }
            })

        } else {
          this.openSnackBar("Please upload category image", "");
        }
      }

    } else {
      this.openSnackBar("Enter all fields", "");
    }
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }

}
