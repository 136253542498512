import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CategoriesComponent } from '../categories/categories.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { priceComparisonValidator  } from '../../validators/validations';
@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit {
  previewUrl: any = "../../../assets/ionic-add-circle.png";
  addCategoryForm: FormGroup;
  AllCategoryInfo: any;
  AllSubCategoryInfo: any;
  selectedCat: string = "All";
  selectedSubCat: string = "All";
  loading: boolean = false;
  isLoading: boolean = false;
  isReadOnly: boolean = false;
  isUpdateOnly: boolean = false;
  baseUrl: string = "";
  imgFile: File;
  options: string = "new";
  constructor(
    private dialogRef: MatDialogRef<CategoriesComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private adminSerivce: AdminService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.baseUrl = this.adminSerivce.baseUrl;
    this.addCategoryForm = this.fb.group({
      productimage: [""],
      ProductName: ["", Validators.required],
      Netweight: ["", [Validators.required,Validators.maxLength(6)]],
      Netweightgrms: ["grams",[Validators.required,Validators.maxLength(6)]],
      MRP_Price: ["", [Validators.required,Validators.maxLength(6)]],
      VMART_Price: ["", [Validators.required,Validators.maxLength(6)]],
      quantity: ["", [Validators.required,Validators.maxLength(6)]],
      description: ["", Validators.required],
    },{ validators: priceComparisonValidator })
    this.getCategories();
   console.log(this.data);
  }

  datapatchValues() {
    let products = this.data.data;
    //this.previewUrl = "" +this.baseUrl +""+products.ProductImage;
    this.AllCategoryInfo.filter((items) => {
      if (items.categoryID == products.Categoryid) {
        this.selectedCat = items.categoryName;
        this.AllSubCategoryInfo = items.subCategorys;
        items.subCategorys.filter((sitems) => {
          if (sitems.subCategoryID == products.subCategoryid) {
            this.selectedSubCat = sitems.subCategoryName;
          }
        })
      }
    })
    const myArray = products.Netweight.split(" ");
    this.addCategoryForm.patchValue({
      ProductName: products.ProductName,
      Netweight: myArray[0],
      Netweightgrms: myArray[1],
      MRP_Price: products.MRP_Price,
      VMART_Price: products.VMART_Price,
      quantity: products.stockquantity,
      description: products.description,
    })
    if (products.ProductImage !== "") {
      this.previewUrl = this.baseUrl + products.ProductImage;
    }
  }
  offerPriceValidator(mrpControlName: string, offerControlName: string) {
    return (formGroup: FormGroup) => {
      const mrpPrice = formGroup.controls[mrpControlName];
      const offerPrice = formGroup.controls[offerControlName];
  
      if (!mrpPrice || !offerPrice) {
        return null;
      }
  
      const mrp = parseFloat(mrpPrice.value);
      const offer = parseFloat(offerPrice.value);
  
      if (offer > mrp) {
        offerPrice.setErrors({ exceededMRP: true });
      } else {
        offerPrice.setErrors(null);
      }
    };
  }
  datapatchEditValues() {
    let products = this.data.data;
    //this.previewUrl = "" +this.baseUrl +""+products.ProductImage;
    this.AllCategoryInfo.filter((items) => {
      if (items.categoryID == products.Categoryid) {
        this.selectedCat = items.categoryName;
        this.AllSubCategoryInfo = items.subCategorys;
        items.subCategorys.filter((sitems) => {
          if (sitems.subCategoryID == products.subCategoryid) {
            this.selectedSubCat = sitems.subCategoryName;
          }
        })
      }
    })
    const myArray = products.Netweight.split(" ");
    this.addCategoryForm.patchValue({
      ProductName: products.ProductName,
      Netweight: myArray[0],
      Netweightgrms: myArray[1],
      MRP_Price: products.MRP_Price,
      VMART_Price: products.VMART_Price,
      quantity: products.stockquantity,
      description: products.description,
    })
    this.previewUrl = this.baseUrl + products.ProductImage;
    this.http
      .get(this.previewUrl, { responseType: "blob" })
      .subscribe((file) => {
        let imgFile = new File([file], "userImg.jpg");
        this.addCategoryForm.get("productimage").setValue(imgFile);
      });
  }
  addcatSubCateData() {
    let products = this.data.data;
    //this.previewUrl = "" +this.baseUrl +""+products.ProductImage;
    this.AllCategoryInfo.filter((items) => {
      if (items.categoryID == products.Categoryid) {
        this.selectedCat = items.categoryName;
        this.AllSubCategoryInfo = items.subCategorys;
        items.subCategorys.filter((sitems) => {
          if (sitems.subCategoryID == products.subCategoryid) {
            this.selectedSubCat = sitems.subCategoryName;
          }
        })
      }
    })
  }

  getCategories() {
    this.adminSerivce.showLoader.next(true);
    let obj = {
      type: "All"
    }
    this.adminSerivce.getAllCategories(obj).subscribe(
      (posRes: any) => {
        if (posRes.response == 3) {
          this.adminSerivce.showLoader.next(false);
          this.AllCategoryInfo = posRes.CategoryInfo;
          if (this.data.option == 'view') {
            this.options = "view";
            this.isReadOnly = true;
            this.datapatchValues();
          }
          if (this.data.option == 'edit') {
            this.options = "edit";
            this.isUpdateOnly = true;
            this.datapatchEditValues();
          }
          if (this.data.option == 'add') {
            this.options = "add";
            this.selectedCat = this.data.cate;
            this.AllCategoryInfo.filter((item) => {
              if (item.categoryName == this.selectedCat) {
                this.AllSubCategoryInfo = item.subCategorys
                this.selectedSubCat = this.data.subcate;
              }
            })
          }
        } else {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminSerivce.showLoader.next(false);
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  onSelectedCategory() {
    if (this.selectedCat == 'All') {
      this.selectedSubCat = "All";
      this.AllSubCategoryInfo = [];
    } else {
      this.AllCategoryInfo.filter((item) => {
        if (item.categoryName == this.selectedCat) {
          this.AllSubCategoryInfo = item.subCategorys
        }
      })
    }
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  onSelectedSubCategory() {

  }

  //File Upload

  fileProgress(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.addCategoryForm.get("productimage").setValue(file);
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  closeTab() {
    let Obj = {
      apicall: false
    }
    this.dialogRef.close(Obj);
  }
  productInsertInfo() {
    if (this.options == "edit") {
      if (this.selectedCat != "All") {
        if (this.selectedSubCat != "All") {
          this.loading = true;
          this.isLoading = true;
          // if (this.addCategoryForm.value.productimage != "") {
          let payLoad = { ...this.addCategoryForm.value };
          delete payLoad.productimage;
          let formData = new FormData();
          let catId = "";
          this.AllCategoryInfo.filter((item) => {
            if (item.categoryName == this.selectedCat) {
              catId = item.categoryID
            }
          })
          let subcatID = "";
          this.AllSubCategoryInfo.filter((item) => {
            if (item.subCategoryName == this.selectedSubCat) {
              subcatID = item.subCategoryID
            }
          })
          // {"Netweight":["100 grams","200 grams","500 grams","1 kg","25 kg","50 kg"]}
          var netWeigh = [];
          if (payLoad.Netweightgrms == "grams") {
            netWeigh.push(payLoad.Netweight + " " + "grams")
          }
          if (payLoad.Netweightgrms == "kg") {
            netWeigh.push(payLoad.Netweight + " " + "kg")
          }
          if (payLoad.Netweightgrms == "ltrs") {
            netWeigh.push(payLoad.Netweight + " " + "ltrs")
          }
          if (payLoad.Netweightgrms == "ltr") {
            netWeigh.push(payLoad.Netweight + " " + "ltr")
          }
          if (payLoad.Netweightgrms == "ml") {
            netWeigh.push(payLoad.Netweight + " " + "ml")
          }
          if (payLoad.Netweightgrms == "qty") {
            netWeigh.push(payLoad.Netweight + " " + "qty")
          }
          if(payLoad.Netweightgrms == "Tray") {
            netWeigh.push(payLoad.Netweight + " " + "Tray")
          }
          let apiPayload = {
            ProductID: this.data.data.ProductID,
            ProductName: payLoad.ProductName,
            Brand: "𝐍𝐮𝐭𝐫𝐢𝐟𝐲 𝐟𝐨𝐨𝐝𝐬",
            MRP_Price: payLoad.MRP_Price,
            VMART_Price: payLoad.VMART_Price,
            quantity: payLoad.quantity,
            description: payLoad.description,
            Netweight: "" + netWeigh,
            Product_Status: this.data.data.Product_Status
          }
          formData.append("productimage", this.addCategoryForm.get("productimage").value);
          formData.append("updateproductInfo", JSON.stringify(apiPayload));
          let token = sessionStorage.getItem('token');
          this.adminSerivce.showLoader.next(true);

          this.adminSerivce.updateProducts(formData, token).subscribe(
            (posRes: any) => {
              if (posRes.response == 3) {
                this.loading = false;
                this.isLoading = false;
                this.openSnackBar(posRes.message, "");
                let Obj1 = {
                  apicall: true
                }
                this.dialogRef.close(Obj1);
              } else {
                this.loading = false;
                this.isLoading = false;
                this.adminSerivce.showLoader.next(false);
                this.openSnackBar(posRes.message, "");
              }
            },
            (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                this.adminSerivce.showLoader.next(false);
                this.loading = false;
                this.isLoading = false;
                this.openSnackBar(err.message, "Client Error");
              } else {
                this.loading = false;
                this.isLoading = false;
                this.openSnackBar(err.message, "Server Error");
              }
            })
          // } else {
          //   this.openSnackBar("Please upload product image", "");
          // }
        } else {
          this.openSnackBar("Select at leaset one subcategory", "")
        }
      } else {
        this.openSnackBar("Select at least one Category", "");
      }
    } else {
      if (this.selectedCat != "All") {
        if (this.selectedSubCat != "All") {
          if (this.addCategoryForm.value.productimage != "") {
            if (this.addCategoryForm.valid) {
              this.isLoading = true;
              let payLoad = { ...this.addCategoryForm.value };
              delete payLoad.productimage;
              let formData = new FormData();
              let catId = "";
              this.AllCategoryInfo.filter((item) => {
                if (item.categoryName == this.selectedCat) {
                  catId = item.categoryID
                }
              })
              let subcatID = "";
              this.AllSubCategoryInfo.filter((item) => {
                if (item.subCategoryName == this.selectedSubCat) {
                  subcatID = item.subCategoryID
                }
              })
              // {"Netweight":["100 grams","200 grams","500 grams","1 kg","25 kg","50 kg"]}
              var netWeigh = [];
              if (payLoad.Netweightgrms == "grams") {
                netWeigh.push(payLoad.Netweight + " " + "grams")
              }
              if (payLoad.Netweightgrms == "kg") {
                netWeigh.push(payLoad.Netweight + " " + "kg")
              }
              if (payLoad.Netweightgrms == "ltrs") {
                netWeigh.push(payLoad.Netweight + " " + "ltrs")
              }
              if (payLoad.Netweightgrms == "ltr") {
                netWeigh.push(payLoad.Netweight + " " + "ltr")
              }
              if (payLoad.Netweightgrms == "ml") {
                netWeigh.push(payLoad.Netweight + " " + "ml")
              }
              if (payLoad.Netweightgrms == "qty") {
                netWeigh.push(payLoad.Netweight + " " + "qty")
              }
              if(payLoad.Netweightgrms == "Tray") {
                netWeigh.push(payLoad.Netweight + " " + "Tray")
              }
              let apiPayload = {
                subCategoryID: subcatID,
                CategoryID: catId,
                ProductName: payLoad.ProductName,
                Brand: "𝐍𝐮𝐭𝐫𝐢𝐟𝐲 𝐟𝐨𝐨𝐝𝐬",
                MRP_Price: payLoad.MRP_Price,
                VMART_Price: payLoad.VMART_Price,
                quantity: payLoad.quantity,
                description: payLoad.description,
                Netweight: "" + netWeigh
              }
              formData.append("productimage", this.addCategoryForm.get("productimage").value);
              formData.append("productInfo", JSON.stringify(apiPayload));
              let token = sessionStorage.getItem('token');
              this.adminSerivce.showLoader.next(true);
              this.adminSerivce.AddProducts(formData, token).subscribe(
                (posRes: any) => {
                  if (posRes.response == 3) {
                    this.loading = false;
                    this.isLoading = false;
                    this.openSnackBar(posRes.message, "");
                    let Obj1 = {
                      apicall: true
                    }
                    this.dialogRef.close(Obj1);
                  } else {
                    this.loading = false;
                    this.isLoading = false;
                    this.adminSerivce.showLoader.next(false);
                    this.openSnackBar(posRes.message, "");
                  }
                },
                (err: HttpErrorResponse) => {
                  if (err.error instanceof Error) {
                    this.adminSerivce.showLoader.next(false);
                    this.loading = false;
                    this.isLoading = false;
                    this.openSnackBar(err.message, "Client Error");
                  } else {
                    this.loading = false;
                    this.isLoading = false;
                    this.openSnackBar(err.message, "Server Error");
                  }
                })

            } else {
              this.openSnackBar("select all fields", "");
            }
          } else {
            this.openSnackBar("Please upload product image", "");
          }
        } else {
          this.openSnackBar("Select at leaset one subcategory", "")
        }
      } else {
        this.openSnackBar("Select at least one Category", "");
      }

    }
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
