import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { FiltersdevineComponent } from '../../components/filtersdevine/filtersdevine.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  fromDate : string ="";
  toDate : string ="";
  pageNo : number = 1;
  size : string = "10";
  ordersData : Array<any> = [];
  totalPageCount : number =1;
  cartId :string = "";
  pageNumbers: Array<any> = [];
  isTotalCountReached: boolean = false;
  orderID :string = "";
  isOrderDetails : boolean = false;
  divineDateFilter : boolean = false;
  category: any;
  jobObj: any;
  keywordSearch: any;
  filterOption: string = "All";
  constructor(
    private adminService:AdminService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.keywordSearch = { ...params };
    });
    if (this.keywordSearch && this.keywordSearch.params && this.keywordSearch.params.search_term) {
      this.filterOption = this.keywordSearch.params.search_term
    }
    if(this.filterOption=="thisMonth"){
      var date = new Date(), y = date.getFullYear(), m = date.getMonth();
      var firstDay = new Date(y, m, 1).setHours(0, 0, 0, 0);
      var lastDay = new Date(y, m + 1, 0).setHours(23, 59, 59, 999);
      this.fromDate =""+firstDay;
      this.toDate = ""+lastDay;
      this.getAllOrders();
    }else{
      this.fromDate =""+new Date("01/06/2023").getTime();
      this.toDate = ""+new Date().getTime();
      this.getAllOrders();
    }
   
  }
  getAllOrders(){
    let obj ={
      fromDate:this.fromDate,
      toDate:this.toDate,
      pageNo : ""+this.pageNo,
      size : ""+this.size
    }
    this.adminService.showLoader.next(true);
    this.adminService.fetchAllDivineOrders(obj).subscribe((posRes) => {
      if(posRes.data.response == 3){
        console.log(posRes);
       this.adminService.showLoader.next(false);
       this.ordersData = posRes.data.OrdersData.reverse();
       this.totalPageCount = posRes.data.pages;
       this.pageNumbers =[];
        for (let i: number = 0; i < this.totalPageCount; i++) {
          this.pageNumbers.push(i + 1);
        }
        if (this.totalPageCount < this.pageNo) {
          this.isTotalCountReached = true;
        } else {
          this.isTotalCountReached = false;
        }
      } else {
        // this.openSnackBar(posRes.message, "");
        this.ordersData = [];
        this.totalPageCount =1;
        this.pageNo = 1;
        this.adminService.showLoader.next(false);
      }
    }, (err: HttpErrorResponse) => {
     
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  displayProduct(items){
    this.cartId = items.orderId;
  }
  displayProductInfo(items){
    this.adminService.showLoader.next(true);
    this.isOrderDetails = true;
    this.orderID = items.orderId;

  }
  dispatchproduct(product){
    let obj ={
      PhoneNumber : ""+product.PhoneNumber.substring(3),
      orderId : product.orderId
    }
    let token = sessionStorage.getItem('token');
    this.adminService.showLoader.next(true);
    this.adminService.dispatchDivineOrders(obj,token).subscribe((posRes) => {
      if(posRes.response == 3){
       this.adminService.showLoader.next(false);
       this.openSnackBar(posRes.message, "");
       this.getAllOrders();
      } else {
        this.openSnackBar(posRes.message, "");
        this.adminService.showLoader.next(false);
      }
    }, (err: HttpErrorResponse) => {
     
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  ordersFilters(){
      let obj = {
        from: "AllCust",
        category: this.category,
        filters: { fromdate:""+this.fromDate,
        todate:""+this.toDate}
      }
      this.pageNo = 1
      let dialogRef = this.dialog.open(FiltersdevineComponent, {
        panelClass: 'col-md-4',
        hasBackdrop: true,
        disableClose: true,
        data: obj
      })
      dialogRef.afterClosed().subscribe(res => {
        if (res && res.dateFilter) {
          this.ordersData = [];
          this.fromDate =""+res.fromdate;
          this.toDate = ""+res.todate;
          this.pageNo = 1;
          this.getAllOrders();
          //this.getJobs()
        }else{
          this.fromDate =""+new Date("01/01/2022").getTime();
          this.toDate = ""+new Date().getTime();
          this.pageNo = 1;
          this.getAllOrders();
        }
      })
  }
  gotoSelectedPage(num){
    this.pageNo = num;
    this.getAllOrders();
  }
  previousPage(){
    if(this.pageNo != 1){
      this.pageNo = this.pageNo-1;
      this.getAllOrders();
    }
    
  }
  nextPage(){
    if(this.pageNo != this.totalPageCount){
      this.pageNo = this.pageNo+1;
      this.getAllOrders();
    }
    
  }


  phonePerefund(items){
    console.log(items)
    let data = {
      merchantId:"M1VUQL0E0GN6",
   transactionId:items.PaymentData.transactionId,
   amount:items.PaymentData.amount,
   merchantOrderId:items.PaymentData.merchantTransactionId,
  //  transactionId
    }
    this.adminService.showLoader.next(true)
    
    this.adminService.phoneperefund(data).subscribe((posRes) => {
console.log(posRes)
      // if(posRes.response == 3){
      //  this.adminService.showLoader.next(false);
      //  this.openSnackBar(posRes.message, "");
      //  this.getAllOrders();
      // } else {
      //   this.openSnackBar(posRes.message, "");
      //   this.adminService.showLoader.next(false);
      // }
    }, (err: HttpErrorResponse) => {
     
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
  receiveMessage(event) {
    this.isOrderDetails = event;
  }

}
